import { Service, StatefulService } from 'react-service-locator';
import { useGetLanguageFromCookie } from '../helpers/CookieHelper';
import { getKeyByValue, LanguageEnum } from '../components/LanguageSwitch/LanguageOption';

type ILoginModal = {
    email: string;
    rememberMe: boolean;
    int?: string;
};

@Service()
export class LoginService extends StatefulService<any> {
    constructor() {
        super();
        this.state = {
            initialized: false,
        };
    }

    async initialize() {
        this.setState({ initialized: true });
    }

    async loginStart(email: string, rememberMe: boolean) {
        if (!this.state.initialized) {
            await this.initialize();
        }

        const languageFromCookie = useGetLanguageFromCookie();
        let langKey = getKeyByValue(LanguageEnum, languageFromCookie);
        if (!langKey) {
            langKey = '0';
        }
        const data = {
            email: email.toLowerCase(),
            rememberMe: rememberMe,
            language: parseInt(langKey, 10),
        };
        return await fetch(process.env.REACT_APP_BASE_API_URI + '/Identity/loginSendEmail', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    }

    async loginPinVerify(email: string, pin: string) {
        if (!this.state.initialized) {
            await this.initialize();
        }

        const data: any = {
            email: email.toLowerCase(),
            pin: pin,
        };
        return await fetch(process.env.REACT_APP_BASE_API_URI + '/identity/loginVerify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    }

    async autoLoginVerify(email: string, totp: string) {
        if (!this.state.initialized) {
            await this.initialize();
        }

        const data: any = {
            email: email.toLowerCase(),
            totp: totp,
        };
        return await fetch(process.env.REACT_APP_BASE_API_URI + '/identity/autoLoginVerify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    }
}
