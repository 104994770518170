import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import SearchPage from './pages/SearchPage/SearchPage';
import ResultPage from './pages/ResultPage/ResultPage';
import DetailPage from './pages/DetailPage/DetailPage';
import Navbar from './components/Navbar/Navbar';
import ExplanationPage from './pages/ExplanationPage/ExplanationPage';
import LoginPage from './pages/Login/LoginPage/LoginPage';
import LoginPinPage from './pages/Login/LoginPinPage/LoginPinPage';
import AutoLogin from './components/AutoLogin/AutoLogin';
import { useService } from 'react-service-locator';
import { AccountService } from './services/account.service';
import SettingsPage from './pages/AdminBoard/SettingsPage/SettingsPage';
import MasterUserDashboard from './pages/AdminBoard/SettingsPage/UsersDashboard/MasterUserDashboard';
import { useGetRoles, useIsUserAnyTypeOfAdmin } from './hooks/UserHooks';
import RegisterNewClient from './pages/AdminBoard/SettingsPage/RegisterNewClient/RegisterNewClient';
import { observer } from 'mobx-react-lite';
import { Toaster } from 'sonner';
import RegisterMasterUser from './pages/AdminBoard/SettingsPage/RegisterMasterUser/RegisterMasterUser';
import { initOnRefresh } from './axios/axiosAuthInterceptor';

const isAuthenticatedFirst = () => {
    const accessToken = localStorage.getItem('refreshToken');
    return !!accessToken;
};

interface PrivateRouteProps extends RouteProps {
    component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(isAuthenticatedFirst);
    const accountService = useService(AccountService);

    const email = localStorage.getItem('UserEmail');
    useEffect(() => {
        const fetchSettings = async () => {
            try {
                if (email) {
                    await accountService.fetchAccountSettings(email);
                } else {
                    setIsAuthenticated(false);
                }
                setIsAuthenticated(true);
            } catch (error) {
                setIsAuthenticated(false);
            }
        };

        fetchSettings(); // Выполняем запрос при монтировании компонента
    }, [email]);
    return (
        <Route {...rest} render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />)} />
    );
};

const MasterPrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
    const [isMasterUser, setIsMasterUser] = useState(useGetRoles()?.includes('MASTER') ?? false);

    useEffect(() => {
        const fetchRoles = async () => {
            const roles = await useGetRoles();
            const isAllowed = roles ? roles.includes('MASTER') : false;
            setIsMasterUser(isAllowed);
        };

        fetchRoles();
    }, []);
    return (
        <Route
            {...rest}
            render={(props) => (isMasterUser ? <PrivateRoute {...props} component={Component} /> : <Redirect to="/" />)}
        />
    );
};

const AnyAdminPrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
    const [isAdminUser, setIsAdminUser] = useState(useIsUserAnyTypeOfAdmin());

    useEffect(() => {
        setIsAdminUser(useIsUserAnyTypeOfAdmin());
    }, []);
    return (
        <Route
            {...rest}
            render={(props) => (isAdminUser ? <PrivateRoute {...props} component={Component} /> : <Redirect to="/" />)}
        />
    );
};

const App: React.FC = observer(() => {
    initOnRefresh();
    return (
        <>
            <Router>
                <Toaster richColors={true} position="top-center" />
                <Navbar />
                <Switch>
                    <PrivateRoute exact path="/" component={SearchPage} />
                    <PrivateRoute exact path="/explanation" component={ExplanationPage} />
                    <PrivateRoute exact path="/results" component={ResultPage} />
                    <PrivateRoute path="/results/:id" component={DetailPage} />
                    <Route exact path="/login" component={LoginPage} />
                    <Route exact path="/login-pin" component={LoginPinPage} />
                    <Route exact path="/autoLogin" component={AutoLogin} />
                    <MasterPrivateRoute exact path="/admin-settings/register-client" component={RegisterNewClient} />
                    <MasterPrivateRoute
                        exact
                        path="/admin-settings/register-master-user"
                        component={RegisterMasterUser}
                    />
                    <AnyAdminPrivateRoute exact path="/admin-settings" component={SettingsPage} />
                    <AnyAdminPrivateRoute exact path="/admin-settings/dashboard" component={MasterUserDashboard} />
                </Switch>
            </Router>
        </>
    );
});

export default App;
