import { concat, isEmpty, uniq } from 'lodash';
import React, { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useService } from 'react-service-locator';
import { LifestyleInterventionService } from '../../services/lifestyle-intervention.service';
import { SearchService } from '../../services/search.service';
import './LifestyleInterventionButton.scss';

interface LifestyleInterventionButtonProps {
    name: string;
    image: string;
    selectedImage: string;
    clickedOutside: boolean;
    setClickedOutside: (value: boolean) => void;
}

const LifestyleInterventionButtonBase = (
    props: LifestyleInterventionButtonProps,
    ref: React.LegacyRef<HTMLDivElement>,
) => {
    const searchService = useService(SearchService);
    const lifestyleInterventionService = useService(LifestyleInterventionService);
    const { t } = useTranslation();
    const [otherLifestyleInterventionEnabled, toggleOtherLifestyleInterventionEnabled] = useState(false);
    const [isCollapsed, collapse] = useState(true);
    useEffect(() => {
        if (props.clickedOutside) {
            collapse(true);
        }
    }, [props.clickedOutside]);
    const toggleCollapsible = (event: React.MouseEvent<HTMLElement>) => {
        props.setClickedOutside(false);
        if (searchService.state.lifestyleInterventionGroup !== props.name) {
            searchService.setLifestyleInterventionGroup(props.name);
        }

        Array.from(document.getElementsByClassName('collapsible')).forEach((c) => {
            if (c !== event.currentTarget) {
                c.classList.remove('active');
                c.classList.remove('has-selection');
                c.classList.remove('current-group');
                collapse(true);
            }
        });
        event.currentTarget.classList.toggle('active');
        collapse(!event.currentTarget.classList.contains('active'));
    };

    const toggleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            if (event.target.value === 'other') {
                toggleOtherLifestyleInterventionEnabled(true);
                searchService.toggleLifestyleInterventionGroupAll(false);
                return;
            }
            if (event.target.value === 'all') {
                searchService.toggleLifestyleInterventionGroupAll(true);
                toggleOtherLifestyleInterventionEnabled(false);
                return;
            }
            searchService.setLifestyleInterventions([
                event.target.value.toLowerCase(),
                ...searchService.state.lifestyleInterventions,
            ]);
            return;
        } else {
            if (event.target.value === 'other') {
                toggleOtherLifestyleInterventionEnabled(false);
                return;
            }
            if (event.target.value === 'all') {
                searchService.toggleLifestyleInterventionGroupAll(false);
                return;
            }
            searchService.setLifestyleInterventions([
                ...searchService.state.lifestyleInterventions.filter((c) => c !== event.target.value.toLowerCase()),
            ]);
            return;
        }
    };

    const [otherValue, setOtherValue] = useState('');

    const addOtherValue = () => {
        if (isEmpty(otherValue)) {
            return;
        }
        searchService.setLifestyleInterventions([
            otherValue.toLowerCase(),
            ...searchService.state.lifestyleInterventions,
        ]);
        lifestyleInterventionService.saveLifestyleIntervention(
            otherValue.toLowerCase(),
            searchService.state.lifestyleInterventionGroup!,
        );
        toggleOtherLifestyleInterventionEnabled(false);
        setOtherValue('');
    };

    const [hasSelection, toggleHasSelection] = useState(false);
    useEffect(() => {
        const t_hasSelection =
            searchService.state.lifestyleInterventionGroup === props.name &&
            (searchService.state.lifestyleInterventions.length !== 0 ||
                searchService.state.lifestyleInterventionGroupAll);
        if (t_hasSelection !== hasSelection && isCollapsed) {
            toggleHasSelection(t_hasSelection);
        }
    }, [searchService.state.lifestyleInterventionGroupAll, searchService.state.lifestyleInterventions, isCollapsed]);

    return (
        <div className="LifestyleInterventionButton" ref={ref}>
            <button
                className={`collapsible${hasSelection ? ' has-selection' : ''}${
                    searchService.state.disease ? '' : ' disabled'
                }`}
                onClick={toggleCollapsible}
                disabled={!searchService.state.disease}
            >
                {!searchService.state.disease || hasSelection ? (
                    <img src={props.selectedImage} alt={`image-${props.name}-selected`} height={56} />
                ) : (
                    <img src={props.image} alt={`image-${props.name}`} height={56} />
                )}
                <p className="body body-normal">
                    {t(`components.search-lifestyle-intervention.${props.name}`)}
                    {isCollapsed ? (
                        <span className="material-icons-outlined">expand_more</span>
                    ) : (
                        <span className="material-icons-outlined">close</span>
                    )}
                </p>
                {searchService.state.lifestyleInterventionGroup == props.name && (
                    <p className="body body-normal">
                        {searchService.state.lifestyleInterventionGroupAll
                            ? 'all'
                            : searchService.state.lifestyleInterventions.join(' | ')}
                    </p>
                )}
            </button>
            <div className="content">
                <p className="body body-bold-small">{t('specify-lifestyle-intervention')}:</p>
                <label className="lifestyle-intervention-selector">
                    <input
                        type="checkbox"
                        value="all"
                        name={`checkbox-${props.name}`}
                        onChange={toggleCheckbox}
                        checked={searchService.state.lifestyleInterventionGroupAll}
                    />

                    <span className="material-icons">
                        {searchService.state.lifestyleInterventionGroupAll ? 'check_box' : 'check_box_outline_blank'}
                    </span>
                    <p className="body body-small">{t('all')}</p>
                </label>
                {uniq(
                    concat(
                        lifestyleInterventionService.state.lifestyleInterventions || [],
                        searchService.state.lifestyleInterventions,
                    ),
                ).map((v, i) => (
                    <label
                        key={`lifestyle-intervention-${props.name}-${i}`}
                        className="lifestyle-intervention-selector"
                    >
                        <input
                            type="checkbox"
                            value={v.toLowerCase()}
                            name={`checkbox-${props.name}`}
                            onChange={toggleCheckbox}
                            checked={searchService.state.lifestyleInterventions.some((c) => c === v.toLowerCase())}
                        />

                        <span className="material-icons">
                            {searchService.state.lifestyleInterventions.some((c) => c === v.toLowerCase())
                                ? 'check_box'
                                : 'check_box_outline_blank'}
                        </span>
                        <p className="body body-small">{v.charAt(0).toUpperCase() + v.substring(1).toLowerCase()}</p>
                    </label>
                ))}
                <label className="lifestyle-intervention-selector">
                    <input
                        type="checkbox"
                        value="other"
                        name={`checkbox-${props.name}`}
                        onChange={toggleCheckbox}
                        checked={otherLifestyleInterventionEnabled}
                    />

                    <span className="material-icons">
                        {otherLifestyleInterventionEnabled ? 'check_box' : 'check_box_outline_blank'}
                    </span>
                    <p className="body body-small">{t('other')}</p>
                </label>
                <label className={`input-other-lifestyle${otherLifestyleInterventionEnabled ? ' enabled' : ''}`}>
                    <input
                        type="text"
                        value={otherValue}
                        disabled={!otherLifestyleInterventionEnabled}
                        onChange={(e) => setOtherValue(e.target.value)}
                        placeholder="Type intervention"
                        className="body body-small"
                    />
                    <button onClick={addOtherValue}>
                        <span className={`material-icons${isEmpty(otherValue) ? '' : ' enabled'}`}>add_circle</span>
                    </button>
                </label>
            </div>
        </div>
    );
};

const LifestyleInterventionButton = forwardRef(LifestyleInterventionButtonBase);

export default LifestyleInterventionButton;
