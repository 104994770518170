import React, { useEffect, useState } from 'react';
import './RegisterNewClient.scss';
import BreadCrumbs from '../../../../components/BreadCrumbs/BreadCrumbs';
import SignedInput from '../../../../components/SignedInput/SignedInput';
import StyledDropdown from '../../../../components/StyledDropdown/StyledDropdown';
import { notEmpty, validateEmail, validatePhoneNumber } from '../../../../helpers/Validators';
import { Title } from '../../../../GeneralModels/Enums/TitleEnum';
import ErrorBanner from '../../../../components/ErrorBanner/ErrorBanner';
import ErrorSign from '../../../../images/icons/error-sign.svg';
import { RegisterClientDto, RegisterService } from '../../../../services/register.service';
import { useService } from 'react-service-locator';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';

const RegisterNewClient: React.FC = () => {
    const titles: string[] = [Title[0], Title[1], Title[2]];
    const errorType = {
        0: 'Nothing',
        1: 'InvalidEmail',
        2: 'InvalidPhone',
        3: 'SomethingEmpty',
        4: 'ServerError',
    };
    const { t } = useTranslation();
    const registerService = useService(RegisterService);
    const [clientAccountEmail, setClientAccountEmail] = useState<string>('');
    const [clientStreet, setClientStreet] = useState<string>('');
    const [companyName, setCompanyName] = useState<string>('');
    const [generalPhone, setGeneralPhone] = useState<string>('');

    const [userEmailAddress, setUserEmailAddress] = useState<string>('');
    const [userFirstName, setUserFirstName] = useState<string>('');
    const [userLastName, setUserLastName] = useState<string>('');
    const [userPhone, setUserPhone] = useState<string>('');
    const [userTitle, setUserTitle] = useState<string>('');
    const resetStates = () => {
        setClientAccountEmail('');
        setCompanyName('');
        setClientStreet('');
        setGeneralPhone('');
        setUserEmailAddress('');
        setUserFirstName('');
        setUserLastName('');
        setUserPhone('');
    };
    const [isReadyToSubmit, setIsReadyToSubmit] = useState(true);
    const [error, setError] = useState({ isError: false, errorMessage: '', errorType: errorType[0] });

    useEffect(() => {
        if (error.isError && error.errorType[1]) {
            setError({ isError: false, errorMessage: '', errorType: errorType[0] });
            setIsReadyToSubmit(true);
        }
    }, [clientAccountEmail, userEmailAddress]);

    useEffect(() => {
        if (error.isError && error.errorType[2]) {
            setError({ isError: false, errorMessage: '', errorType: errorType[0] });
            setIsReadyToSubmit(true);
        }
    }, [userPhone, generalPhone]);

    useEffect(() => {
        if (error.isError && error.errorType[3]) {
            setError({ isError: false, errorMessage: '', errorType: errorType[0] });
            setIsReadyToSubmit(true);
        }
    }, [
        clientAccountEmail,
        clientStreet,
        companyName,
        generalPhone,
        userEmailAddress,
        userFirstName,
        userLastName,
        userPhone,
        userTitle,
    ]);
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        toast.dismiss;
        if (!isReadyToSubmit) {
            return;
        }
        const validationDtoKeysToTranslate: { [key: string]: string } = {
            'General email': t('pages.register-client-page.GeneralEmail'),
            'Business address': t('pages.register-client-page.BusinessAddress'),
            'Company name': t('pages.register-client-page.CompanyName'),
            'General phone': t('pages.register-client-page.GeneralPhone'),
            'User email': t('pages.register-client-page.UserEmail'),
            'First name': t('pages.register-client-page.FirstName'),
            'Last name': t('pages.register-client-page.LastName'),
            'User phone': t('pages.register-client-page.UserPhone'),
            'User title': t('pages.register-client-page.UserTitle'),
        };
        const validEmail = validateEmail(userEmailAddress) && validateEmail(clientAccountEmail);
        const validationDto = {
            'General email': clientAccountEmail,
            'Business address': clientStreet,
            'Company name': companyName,
            'General phone': generalPhone,
            'User email': userEmailAddress,
            'First name': userFirstName,
            'Last name': userLastName,
            'User phone': userPhone,
            'User title': userTitle,
        };

        if (!validEmail) {
            setError({
                isError: true,
                errorMessage: t('pages.register-client-page.InvalidEmail'),
                errorType: errorType[1],
            });
            setIsReadyToSubmit(false);
            return;
        }
        const validPhone = validatePhoneNumber(generalPhone) && validatePhoneNumber(userPhone);
        if (!validPhone) {
            setError({
                isError: true,
                errorMessage: t('pages.register-client-page.InvalidPhone'),
                errorType: errorType[2],
            });
            setIsReadyToSubmit(false);
            return;
        }
        const isSomethingEmpty = notEmpty(validationDto);
        if (isSomethingEmpty != null) {
            const errorThing = validationDtoKeysToTranslate[isSomethingEmpty];
            setError({
                isError: true,
                errorMessage: `${errorThing} ${t('pages.register-client-page.FieldIsEmpty')}`,
                errorType: errorType[3],
            });
            setIsReadyToSubmit(false);
            return;
        }

        const registerClientDto: RegisterClientDto = {
            Client: {
                GeneralEmail: validationDto['General email'],
                GeneralPhone: validationDto['General phone'],
                Company: validationDto['Company name'],
                Address: validationDto['Business address'],
            },
            User: {
                UserEmail: validationDto['User email'],
                UserPhone: validationDto['User phone'],
                FirstName: validationDto['First name'],
                LastName: validationDto['Last name'],
                Title: Title[validationDto['User title'] as keyof typeof Title],
            },
        };
        const loadToast = toast.loading(t('pages.register-client-page.TryingToCreate'));
        const request = await registerService.registerNewClient(registerClientDto);
        if (request.isError) {
            toast.error(t('pages.register-client-page.SomethingWentWrong'), { id: loadToast });
            if (request.errorType == 'DuplicateEmail') {
                setError({ isError: true, errorMessage: request.errorDescription, errorType: errorType[1] });
            } else {
                setError({ isError: true, errorMessage: request.errorDescription, errorType: errorType[4] });
            }
            setIsReadyToSubmit(false);
            return;
        } else if (!request.isError) {
            toast.success(t('pages.register-client-page.SuccessfullyCreated'), { id: loadToast });
            resetStates();
            return null;
        } else {
            toast.error(t('pages.register-client-page.SomethingWentWrong'), { id: loadToast });
        }
    };

    const handleSelect = (option: string) => {
        setUserTitle(option);
    };

    return (
        <div id="registerUserPagePage" className="register__user">
            <BreadCrumbs
                breadcrumbs={[
                    { to: '/', name: t('search') },
                    { to: '/admin-settings', name: t('components.navbar.settings') },
                    { to: '/admin-settings/register-user', name: t('pages.register-client-page.RegisterNewClient') },
                ]}
            />
            <div className="error__message">
                {error.isError && (
                    <ErrorBanner
                        bannerHeader={t('pages.register-client-page.FailedRegister')}
                        bannerDescription={error.errorMessage}
                        bannerIcon={ErrorSign}
                    ></ErrorBanner>
                )}
            </div>
            <div className="card">
                <form className="styled__form" onSubmit={handleSubmit}>
                    <div className="input__card">
                        <div className="client__info">
                            <div className="description__text">{t('pages.register-client-page.ClientAccount')}</div>
                            <div className="client__inputs">
                                <SignedInput
                                    placeholder={'voorbeeld@voorbee ld.nl'}
                                    setInputValue={setClientAccountEmail}
                                    inputValue={clientAccountEmail}
                                    isError={
                                        error.isError &&
                                        (error.errorType == errorType[1] || error.errorType == errorType[3])
                                    }
                                    label={t('pages.register-client-page.GeneralEmail')}
                                />
                                <SignedInput
                                    placeholder={'+123456789'}
                                    setInputValue={setGeneralPhone}
                                    inputValue={generalPhone}
                                    isError={
                                        error.isError &&
                                        (error.errorType == errorType[2] || error.errorType == errorType[3])
                                    }
                                    label={t('pages.register-client-page.GeneralPhone')}
                                />
                                <SignedInput
                                    placeholder={'LifeSight.me'}
                                    setInputValue={setCompanyName}
                                    inputValue={companyName}
                                    isError={error.isError && error.errorType == errorType[3]}
                                    label={t('pages.register-client-page.NameOfCompany')}
                                />
                                <SignedInput
                                    placeholder={'Sunnyside Avenue, 123, 45678, Moontown, Starvoyage'}
                                    setInputValue={setClientStreet}
                                    inputValue={clientStreet}
                                    isError={error.isError && error.errorType == errorType[3]}
                                    label={t('pages.register-client-page.BusinessAddress')}
                                />
                            </div>
                        </div>
                        <div className="client__info">
                            <div className="description__text">{t('pages.register-client-page.UserAccountInfo')}</div>
                            <div className="client__inputs">
                                <SignedInput
                                    placeholder={'voorbeeld@voorbee ld.nl'}
                                    setInputValue={setUserEmailAddress}
                                    inputValue={userEmailAddress}
                                    isError={
                                        error.isError &&
                                        (error.errorType == errorType[1] || error.errorType == errorType[3])
                                    }
                                    label={t('pages.register-client-page.UserEmail')}
                                />
                                <SignedInput
                                    placeholder={'+123456789'}
                                    setInputValue={setUserPhone}
                                    inputValue={userPhone}
                                    isError={
                                        error.isError &&
                                        (error.errorType == errorType[2] || error.errorType == errorType[3])
                                    }
                                    label={t('pages.register-client-page.UserPhone')}
                                />
                                <SignedInput
                                    placeholder={'Mikuru'}
                                    setInputValue={setUserFirstName}
                                    inputValue={userFirstName}
                                    isError={error.isError && error.errorType == errorType[3]}
                                    label={t('pages.register-client-page.FirstName')}
                                />
                                <SignedInput
                                    placeholder={'Asahina'}
                                    setInputValue={setUserLastName}
                                    inputValue={userLastName}
                                    isError={error.isError && error.errorType == errorType[3]}
                                    label={t('pages.register-client-page.LastName')}
                                />
                                <StyledDropdown
                                    options={titles}
                                    onSelect={handleSelect}
                                    placeHolder={'(Mr. / Mrs. / Ms.)'}
                                    isError={error.isError && error.errorType == errorType[3]}
                                    sign={t('pages.register-client-page.Title')}
                                />
                            </div>
                        </div>
                    </div>
                    <button
                        disabled={!isReadyToSubmit}
                        type="submit"
                        className={`button__submit ${isReadyToSubmit ? ' button__submit-ready' : ''}`}
                    >
                        {t('pages.register-client-page.Submit')}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default RegisterNewClient;
