import React, { useEffect, useState } from 'react';
import './Article.scss';
import RatingGroup from '../RatingGroup/RatingGroup';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import i18next from 'i18next';
import { fromEnglish } from '../../translation';
import { IArticle, SearchService } from '../../services/search.service';
import { useService } from 'react-service-locator';
import { Immutable } from 'react-service-locator/dist/types/immutable';

const Article: React.FC<{ article: Immutable<IArticle> }> = (props: { article: Immutable<IArticle> }) => {
    const [ln, setLn] = useState(i18next.resolvedLanguage);
    i18next.on('languageChanged', (language) => setLn(language));
    const [title, setTitle] = useState<string>(props.article.title);
    const [keywords, setKeywords] = useState<string[]>([]);
    const searchService = useService(SearchService);
    useEffect(() => {
        fromEnglish(props.article.title).then(setTitle);
        Promise.all(
            props.article.keywords
                .map((c) => c.trim())
                .filter((d) => !isEmpty(d))
                .map((e) => fromEnglish(e ?? '')),
        ).then((d) => setKeywords(d.map((e) => e.toLowerCase())));
    }, [ln, searchService.state.result?.currentPage]);
    return (
        <Link className="link-unstyled article-row" to={'/results/' + props.article.pmid}>
            <div>
                <h3>{title}</h3>
                <div className="keywords">
                    {keywords.slice(0, 5).map((c, i) => (
                        <span key={'keyword-' + props.article.pmid + '-' + i} className="body body-normal">
                            {c}
                        </span>
                    ))}
                </div>
            </div>
            <RatingGroup article={props.article} />
        </Link>
    );
};

export default Article;
