import { concat } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, useParams } from 'react-router-dom';
import { useService } from 'react-service-locator';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import DetailedArticle from '../../components/DetailedArticle/DetailedArticle';
import { SearchService } from '../../services/search.service';
import './DetailPage.scss';

const DetailPage: React.FC = () => {
    const searchService = useService(SearchService);
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    if (searchService.shouldRedirect) {
        return <Redirect to="/" />;
    }
    const title = searchService.state.result?.currentPage.find((c) => c.pmid === id)?.title ?? '';
    const splitted_title = title.split(' ');
    const shortened_title =
        splitted_title.length <= 6
            ? title
            : concat(splitted_title.slice(0, 3), ['[...]'], splitted_title.slice(-3)).join(' ');
    return (
        <div id="detailpage" className="detail">
            <BreadCrumbs
                breadcrumbs={[
                    { to: '/', name: t('search') },
                    {
                        to: '/results',
                        name: `${searchService.state.disease} ${t('search-results').toLocaleLowerCase()}`,
                    },
                    { to: '/results/:id', name: shortened_title },
                ]}
            />
            <DetailedArticle />
        </div>
    );
};

export default DetailPage;
