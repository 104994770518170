import React, { useState, useEffect } from 'react';
import { languageWithFlagOptions } from './LanguageOption';
import './LanguageSwitch.scss';
import i18n from 'i18next';
import { useService } from 'react-service-locator';
import { AccountService } from '../../services/account.service';

const LanguageSwitch: React.FC = () => {
    const [currentLanguage, setCurrentLanguage] = useState(languageWithFlagOptions[0]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const accountService = useService(AccountService);
    useEffect(() => {
        const languageFromCookie = getLanguageFromCookie();
        if (languageFromCookie) {
            const selectedLanguage = languageWithFlagOptions.find((option) => option.key === languageFromCookie);
            if (selectedLanguage) {
                setCurrentLanguage(selectedLanguage);
                i18n.changeLanguage(selectedLanguage.key);
            }
        }
    }, []);

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    const handleLanguageChange = (language: any) => {
        setCurrentLanguage(language);
        i18n.changeLanguage(language.key);
        setLanguageInCookie(language.key); // Set language in cookie
        setIsDropdownOpen(false);
        accountService.setNewLanguage(language.key);
    };

    const getLanguageFromCookie = () => {
        const languageCookie = document.cookie.split(';').find((cookie) => cookie.trim().startsWith('language='));
        if (languageCookie) {
            return languageCookie.split('=')[1];
        }
        return null;
    };

    const setLanguageInCookie = (lang: string) => {
        document.cookie = `language=${lang};expires=Thu, 01 Jan 2030 00:00:00 UTC;path=/;`; // Updated expiry to a future date
    };

    return (
        <div className="language-picker roundborders">
            <div className="current-language" onClick={toggleDropdown}>
                <img src={currentLanguage.image} alt={currentLanguage.text} />
                {currentLanguage.text}
            </div>
            <ul className={`language-dropdown ${isDropdownOpen ? 'open' : ''}`}>
                {languageWithFlagOptions.map(
                    (option) =>
                        option.key !== currentLanguage.key && (
                            <li key={option.key} onClick={() => handleLanguageChange(option)}>
                                <img src={option.image} alt={option.text} />
                                {option.text}
                            </li>
                        ),
                )}
            </ul>
        </div>
    );
};

export default LanguageSwitch;
