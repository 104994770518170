import React from 'react';
import './ErrorBanner.scss';

const ErrorBanner: React.FC<{
    bannerHeader: string;
    bannerDescription: string;
    bannerIcon?: any;
}> = ({ bannerHeader, bannerDescription, bannerIcon }) => {
    return (
        <div className="error-banner">
            {bannerIcon && <img src={bannerIcon} alt="Error Icon" className="error-message-icon" />}
            <div className="error-message-text">
                <span className="error-message-header">{bannerHeader}</span>
                <br />
                <span className="error-message-description">{bannerDescription}</span>
            </div>
        </div>
    );
};

export default ErrorBanner;
