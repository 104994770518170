import React from 'react';
import './SignedInput.scss';

const SignedInput: React.FC<{
    placeholder: string;
    label?: string;
    setIsCheckboxChecked?: any;
    setInputValue: any;
    inputValue: string;
    isCheckboxChecked?: boolean;
    checkboxLabel?: string;
    icon?: any;
    isError?: boolean;
    inputFieldWidth?: string;
}> = ({
    placeholder,
    label,
    setIsCheckboxChecked,
    isCheckboxChecked,
    setInputValue,
    inputValue,
    checkboxLabel,
    icon,
    isError,
    inputFieldWidth,
}) => {
    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
    };

    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
    };

    return (
        <div className="input-field signed-input">
            {label && <label className="label">{label}</label>}
            <div className="input-container">
                <input
                    className={`textfield__settings textfield${isError ? ' error' : ''}`} // Conditionally add error class
                    style={{
                        ...(icon
                            ? {
                                  backgroundPosition: '10px, 10px',
                                  padding: '12px 20px 12px 40px',
                                  boxSizing: 'border-box',
                              }
                            : {}),
                        ...(inputFieldWidth != null
                            ? {
                                  maxWidth: `${inputFieldWidth}`,
                              }
                            : {}),
                    }}
                    type="text"
                    placeholder={placeholder}
                    value={inputValue}
                    onChange={handleInputChange}
                />

                {icon != null && <img src={icon} className={'innerIcon'} />}
            </div>
            {checkboxLabel && (
                <div
                    className={`checkbox-container${isCheckboxChecked ? ' checked' : ''}`}
                    onClick={handleCheckboxChange}
                >
                    <div className="checkmark"></div>
                    <label className="checkbox-label">{checkboxLabel}</label>
                </div>
            )}
        </div>
    );
};

export default SignedInput;
