// UserStore.ts
import { makeAutoObservable } from 'mobx';

class UserStore {
    constructor() {
        makeAutoObservable(this);
    }
    token: string | null = localStorage.getItem('accessToken');
    roles: string[] | null = [];

    setToken(token: string) {
        this.token = token;
    }

    setRoles(roles: string[]) {
        this.roles = roles;
    }

    clearToken() {
        this.token = null;
    }
}

export default new UserStore();
