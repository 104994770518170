import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select, { StylesConfig } from 'react-select';
import { useService } from 'react-service-locator';
import { DiseaseService } from '../../services/disease.service';
import { SearchService } from '../../services/search.service';
import './DiseaseSymptomInput.scss';

type OptionType = { label: string; value: string; disabled: boolean };

const customSelectStyles: StylesConfig<{ label: string; value: string; disabled: boolean }> = {
    option: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? '#8E99B7' : '#0B2566',
        fontWeight: state.isDisabled ? 500 : 600,
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#8E99B7',
        fontWeight: 500,
    }),
    input: (provided) => ({
        ...provided,
        color: '#0B2566',
        fontWeight: 600,
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#0B2566',
        fontWeight: 600,
    }),
};

const diseaseDisabledOptions: OptionType[] = [
    { label: 'Cardiovascular diseases (comes later in application)', value: 'hart- en vaatziekten', disabled: true },
    { label: 'Multiple Sclerosis (comes later in application)', value: 'multiple sclerosis', disabled: true },
    { label: 'Other disease (comes later in application)', value: 'andere aandoening', disabled: true },
];

const DiseaseSymptomInput: FC = () => {
    const searchService = useService(SearchService);
    const diseaseService = useService(DiseaseService);
    const selectDisease = (disease?: string) => {
        searchService.setDisease(disease);
        searchService.setSymptoms([]);
    };
    const { t } = useTranslation();
    useEffect(() => {
        diseaseService.fetchDiseases();
    }, []);
    return (
        <div className="DiseaseSymptomInput">
            <div>
                <p className="body body-normal">{t('pages.search-page.disease')}</p>
                <Select
                    className="body body-normal"
                    value={
                        searchService.state.disease
                            ? {
                                  label: searchService.state.disease,
                                  value: searchService.state.disease,
                                  disabled: false,
                              }
                            : undefined
                    }
                    isMulti={false}
                    options={[
                        ...diseaseService.state.diseases.map((d) => ({ label: d, value: d, disabled: false })),
                        ...diseaseDisabledOptions,
                    ]}
                    onChange={(v) => selectDisease(v?.value)}
                    isOptionDisabled={(v: OptionType) => v.disabled}
                    isClearable={true}
                    placeholder={t('components.search-disease.select')}
                    styles={customSelectStyles}
                />
            </div>
            <div>
                <p
                    className={`body body-normal${
                        diseaseService.state.symptoms && diseaseService.state.symptoms.length !== 0 ? '' : ' disabled'
                    }`}
                >
                    {t('pages.search-page.symptom')}
                </p>
                <Select
                    className="select body body-normal"
                    value={searchService.state.symptoms.map((s) => ({ label: s, value: s, disabled: false }))}
                    isMulti={true}
                    options={diseaseService.state.symptoms?.map((c) => ({ value: c, label: c, disabled: false })) ?? []}
                    onChange={(v) => searchService.setSymptoms(v.map((s) => s.value))}
                    isDisabled={!diseaseService.state.symptoms || diseaseService.state.symptoms.length === 0}
                    placeholder={t('components.search-symptom.select')}
                    styles={customSelectStyles}
                />
            </div>
        </div>
    );
};

export default DiseaseSymptomInput;
