import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import './DetailedArticle.scss';
import $ from 'jquery';
import { fromEnglish } from '../../translation';
import RatingGroup from '../RatingGroup/RatingGroup';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ReactComponent as ChevronLeft } from '../../images/icons/chevron-left.svg';
import { Link } from 'react-router-dom';
import { isEmpty, uniq } from 'lodash';
import { IArticle, SearchService } from '../../services/search.service';
import { useService } from 'react-service-locator';

const DetailedArticle: React.FC = () => {
    const [ln, setLn] = useState(i18next.resolvedLanguage);
    i18next.on('languageChanged', (language) => setLn(language));
    const { id } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const [conclusion, setConclusion] = useState(t('components.detailed-article.fetching') as string);
    const searchService = useService(SearchService);
    const document: IArticle = searchService.state.result?.currentPage.find((c) => c.pmid === id) as IArticle;
    const [title, setTitle] = useState(document.title);
    const [keywords, setKeywords] = useState(document.keywords);
    const [researchPopulation, setResearchPopulation] = useState(document.researchPopulation);
    const getConclusion = async () => {
        const response = await fetch(
            'https://eutils.ncbi.nlm.nih.gov/entrez/eutils/efetch.fcgi?db=pubmed&id=' + document.pmid + '&rettype=xml',
        );
        const xml = await response.text();
        const data = $.parseXML(xml);
        let result = '';
        const searchTerms = ['Results', 'Result', 'Conclusions', 'Conclusion'];
        for (const searchTerm of searchTerms) {
            const lowerCaseSearchTerm = searchTerm.toLowerCase();
            const upperCaseSearchTerm = searchTerm.toUpperCase();
            const casedSearchTerms = [upperCaseSearchTerm, lowerCaseSearchTerm, searchTerm];
            for (const casedSearchTerm of casedSearchTerms) {
                result = data.evaluate(
                    `/PubmedArticleSet/PubmedArticle/MedlineCitation/Article/Abstract/AbstractText[@Label="${casedSearchTerm}"]`,
                    data,
                    null,
                    XPathResult.STRING_TYPE,
                ).stringValue;
                if (result !== '') {
                    break;
                }
            }
            if (result !== '') {
                break;
            }
            for (const casedSearchTerm of casedSearchTerms) {
                result = data.evaluate(
                    `/PubmedArticleSet/PubmedArticle/MedlineCitation/Article/Abstract/AbstractText[@NlmCategory="${casedSearchTerm}"]`,
                    data,
                    null,
                    XPathResult.STRING_TYPE,
                ).stringValue;
                if (result !== '') {
                    break;
                }
            }
            if (result !== '') {
                break;
            }
        }
        if (result === '') {
            result = 'Conclusions of this study are not available, please see the abstract.';
        }
        return fromEnglish(result);
    };
    useEffect(() => {
        getConclusion().then(setConclusion);
        fromEnglish(document.title).then(setTitle);
        Promise.all(
            document.keywords
                .map((c) => c.trim())
                .filter((d) => !isEmpty(d))
                .map((e) => fromEnglish(e ?? '')),
        ).then((d) => setKeywords(uniq(d.map((e) => e.toLowerCase()))));
        if (document.researchPopulation) {
            fromEnglish(document.researchPopulation).then(setResearchPopulation);
        }
    }, [ln]);
    return (
        <div className="DetailArticle card">
            <Link className="back-button" to="/results">
                <ChevronLeft height={32} />
            </Link>
            <div>
                <div className="keywords">
                    {uniq([searchService.state.lifestyleInterventionGroup, ...keywords])
                        .slice(0, 9)
                        .map((c, i) => (
                            <span key={'keyword-' + i} className="body body-normal">
                                {c}
                            </span>
                        ))}
                </div>
                <p className="body body-big research-population">
                    {document.publicationDate && !isNaN(document.publicationDate.getDate())
                        ? document.publicationDate.toLocaleDateString()
                        : t('unknown-publication-date')}
                    {researchPopulation ? ` | ${t('study-population')}: ${researchPopulation}` : ''}
                </p>
                <h2>{title}</h2>
                <p className="body body-big conclusion">{conclusion}</p>
            </div>
            <RatingGroup article={document as IArticle}>
                <div className="relevant-links">
                    <h3>{t('components.detailed-article.relevant-links')}:</h3>
                    <a
                        className="body body-bold-big btn"
                        target="_blank"
                        href={`https://pubmed.ncbi.nlm.nih.gov/${document.pmid}`}
                        rel="noreferrer"
                    >
                        <span className="material-icons-outlined">description</span>{' '}
                        {t('components.detailed-article.abstract')}
                    </a>
                    <a
                        href={`https://www.doi.org/${document.dois[0]}`}
                        target="_blank"
                        rel="noreferrer"
                        className="body body-bold-big btn"
                    >
                        <span className="material-icons-outlined">link</span>{' '}
                        {t('components.detailed-article.full-article')}
                    </a>
                </div>
            </RatingGroup>
        </div>
    );
};

export default DetailedArticle;
