/* eslint-disable i18next/no-literal-string */
import { decode, encode } from 'html-entities';
import i18next from 'i18next';
import { nth } from 'lodash';

const subscriptionKey = process.env.REACT_APP_TRANSLATION_KEY;
const endpoint = 'https://api.cognitive.microsofttranslator.com';

// Add your location, also known as region. The default is global.
// This is required if using a Cognitive Services resource.
const translateLocation = 'global';

const getFirstCountryLanguage = () => {
    // switch (i18next.resolvedLanguage.toUpperCase()) {
    //     case 'US':
    //         return 'en';
    //     case 'NL':
    //         return 'nl';
    //     default:
    //         return 'nl';
    // }
    return 'en';
};

const translateAsync = async (text: string, to: string) => {
    if (getFirstCountryLanguage() === 'en') {
        return text;
    }
    try {
        const body = JSON.stringify([
            {
                text: encode(text, { mode: 'extensive' }),
            },
        ]);
        const res = await fetch(`${endpoint}/translate?api-version=3.0&to=${to}`, {
            method: 'post',
            headers: {
                'Ocp-Apim-Subscription-Key': subscriptionKey as string,
                'Ocp-Apim-Subscription-Region': translateLocation,
                'Content-type': 'application/json',
            },
            body: body,
            mode: 'cors',
        });
        const json = await (res.json() as Promise<[{ translations?: [{ text?: string }] }]>);
        const response = nth(nth(json)?.translations, 0)?.text ?? '';
        return decodeURI(decode(response));
    } catch (error) {
        console.warn('Translate failed, returned at English');
        return text;
    }
};

export const fromEnglish: (text: string) => Promise<string> = async (text: string) => {
    const t = await translateAsync(text, getFirstCountryLanguage());
    return t;
    //return replaceSpecialChars(t);
};

export const toEnglish: (text: string) => Promise<string> = async (text: string) => translateAsync(text, 'en');

const replaceSpecialChars: (text: string) => Promise<string> = async (text: string) => {
    const matches = text.match(/&.*?;/gm);
    if (matches === null) {
        return text;
    }
    const mapped = await Promise.all(
        matches.map(async (c) => ({
            origin: c,
            translated: await translateAsync(c.substring(1, -1), 'en'),
        })),
    );
    mapped.forEach((c) => {
        const rg = new RegExp(c.origin, 'gm');
        text = text.replaceAll(rg, c.translated);
    });
    text = text.replace(/&$/, '.');
    text = text.replaceAll(/([\p{L}0-9])& /gmu, '$1. ');
    return text;
};
