import React from 'react';
import { ReactComponent as SVGLogo } from '../../../../images/icons/logo.svg';
import DocumentIcon from '../../../../images/icons/document.svg';
import PlusIcon from '../../../../images/icons/plus-in-circle.svg';
import './MasterUserDashboard.scss';
import BreadCrumbs from '../../../../components/BreadCrumbs/BreadCrumbs';
import { t } from 'i18next';

const MasterUserDashboard: React.FC = () => {
    return (
        <div id="settingsPage" className="settings">
            <BreadCrumbs
                breadcrumbs={[
                    { to: '/', name: t('search') },
                    { to: '/admin-settings', name: 'Settings' },
                    { to: '/master-dashboard', name: 'Dashboard' },
                ]}
            />
            <div className="card">
                <div className="title">
                    <SVGLogo height={50} width="auto" />
                </div>
            </div>
        </div>
    );
};

export default MasterUserDashboard;
