import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import axiosInstance from '../../axios/axiosAuthInterceptor';
import { useService } from 'react-service-locator';
import { LoginService } from '../../services/login-service';

const AutoLogin: React.FC = () => {
    const loginService = useService(LoginService);
    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const email = query.get('email');
    const totp = query.get('totp');

    useEffect(() => {
        const fetchData = async () => {
            if (email && totp) {
                try {
                    const response = await loginService.autoLoginVerify(email, totp);
                    if (response.ok) {
                        const responseData = await response.json();
                        localStorage.setItem('accessToken', responseData.accessToken);
                        localStorage.setItem('refreshToken', responseData.refreshToken);
                        history.push('/');
                    }
                } catch (error) {
                    console.error('Error fetching data:', error);
                    history.push('/login');
                }
            }
        };

        fetchData();
    }, [email, totp, history]);

    return null;
};

export default AutoLogin;
