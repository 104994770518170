import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { ReactComponent as SVGLogo } from '../../images/icons/logo.svg';

import './ExplanationPage.scss';

const ExplanationPage: FC = () => {
    const { t } = useTranslation();
    return (
        <div id="explanationpage" className="explanation">
            <BreadCrumbs
                breadcrumbs={[
                    { to: '/', name: t('search') },
                    { to: '/explanation', name: t('explanation') },
                ]}
            />
            <div className="card">
                <div className="title">
                    <SVGLogo height={50} width="auto" />
                </div>
                <p className="body body-big">{t('pages.explanation-page.platform')}</p>
                <br />
                <p className="body body-big">{t('pages.explanation-page.algorithm')}</p>
                <br />
                <p className="body body-big">{t('pages.explanation-page.considered-factors.header')}</p>
                <ul>
                    <li className="body body-big">{t('pages.explanation-page.considered-factors.study-design')}</li>
                    <li className="body body-big">{t('pages.explanation-page.considered-factors.study-group-size')}</li>
                    <li className="body body-big">{t('pages.explanation-page.considered-factors.study-duration')}</li>
                    <li className="body body-big">{t('pages.explanation-page.considered-factors.affiliations')}</li>
                    <li className="body body-big">{t('pages.explanation-page.considered-factors.citations')}</li>
                    <li className="body body-big">
                        {t('pages.explanation-page.considered-factors.impact-factor-journal')}
                    </li>
                </ul>
                <p className="body body-big">{t('pages.explanation-page.no-reviews')}</p>
                <br />
                <p className="body body-big">{t('pages.explanation-page.result')}</p>
                <br />
                <p className="body body-big">{t('pages.explanation-page.indicators')}</p>
            </div>
        </div>
    );
};

export default ExplanationPage;
