import React from 'react';
import './Pagination.scss';
import { ReactComponent as SVGNext } from '../../images/icons/chevron-right.svg';
import { ReactComponent as SVGPrevious } from '../../images/icons/chevron-left.svg';
import { useTranslation } from 'react-i18next';
import { useService } from 'react-service-locator';
import { SearchService } from '../../services/search.service';

const Pagination: React.FC = () => {
    // const page_number = useSelector((state: RootState) => state.results.page_number);
    const searchService = useService(SearchService);
    if (!searchService.state.result) {
        return <></>;
    }
    const result = searchService.state.result;
    const p_pages = result.previousPages.map((_v, i, arr) => {
        return result.currentPageNumber - arr.length + i;
    });
    const n_pages = result.nextPages.map((_v, i) => {
        return result.currentPageNumber + i + 1;
    });
    const gotoPrevious = () => {
        searchService.gotoPage(result.currentPageNumber - 1);
    };
    const gotoNext = () => {
        searchService.gotoPage(result.currentPageNumber + 1);
    };
    const gotoPage = (page: number) => {
        searchService.gotoPage(page);
    };
    const { t } = useTranslation();
    return (
        <ul className="pagination">
            <li className="previous">
                <button
                    className={`body body-big link${p_pages.length === 0 ? ' disabled' : ''}`}
                    onClick={gotoPrevious}
                    disabled={p_pages.length === 0}
                >
                    <SVGPrevious /> {t('components.pagination.previous')}
                </button>
            </li>
            {p_pages.slice(-2).map((v, i) => (
                <li key={'p_page-' + i}>
                    <button className="body body-big btn btn-enabled" onClick={() => gotoPage(v)}>
                        {v}
                    </button>
                </li>
            ))}
            <li>
                <button className="body body-big btn btn-active" disabled>
                    {searchService.state.result.currentPageNumber}
                </button>
            </li>
            {n_pages.slice(0, 2).map((v, i) => (
                <li key={'n_page-' + i}>
                    <button className="body body-big btn btn-enabled" onClick={() => gotoPage(v)}>
                        {v}
                    </button>
                </li>
            ))}
            <li className="next">
                <button
                    className={`body body-big link${n_pages.length === 0 ? ' disabled' : ''}`}
                    onClick={gotoNext}
                    disabled={n_pages.length === 0}
                >
                    {t('components.pagination.next')} <SVGNext />
                </button>
            </li>
        </ul>
    );
};

export default Pagination;
