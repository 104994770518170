import { Service, StatefulService } from 'react-service-locator';
import axiosInstance from '../axios/axiosAuthInterceptor';

type ISymptom = {
    id: number;
    value: string;
};

type IDisease = {
    id: number;
    value: string;
    symptoms: ISymptom[];
};

@Service()
export class DiseaseService extends StatefulService<{
    diseases: string[];
    symptoms?: string[];
}> {
    private disease_symptoms: IDisease[];

    constructor() {
        super({ diseases: [] });
        this.disease_symptoms = [];
        this.fetchDiseases();
    }

    async fetchDiseases() {
        const response = await axiosInstance.get('/Disease/all');
        this.disease_symptoms = (await response.data) as IDisease[];
        const diseases = this.disease_symptoms.map((c) => c.value);
        this.setState({ diseases });
    }

    setDisease(disease?: string) {
        const symptoms = this.disease_symptoms.find((c) => c.value === disease)?.symptoms.map((d) => d.value);
        this.setState({ symptoms });
    }
}
