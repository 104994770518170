import React, { useEffect, useState } from 'react';
import { ReactComponent as SVGLogo } from '../../../images/icons/logo.svg';
import DocumentIcon from '../../../images/icons/document.svg';
import PlusInCircleIcon from '../../../images/icons/plus-in-circle.svg';
import PlusIcon from '../../../images/icons/plus.svg';
import './SettingsPage.scss';
import MasterUserIcon from '../../../images/icons/master-user.svg';
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs';
import userStore from '../../../MobXStorage/UserStore';
import { useGetRoles } from '../../../hooks/UserHooks';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';
import { useHistory } from 'react-router-dom';

const SettingsPage: React.FC = () => {
    const { t } = useTranslation();
    const [isMasterUser, setIsMasterUser] = useState(false);
    const history = useHistory();

    const workInProgress = () => {
        toast.message('Work in progress...');
    };

    useEffect(() => {
        const roles = useGetRoles();
        if (roles) {
            setIsMasterUser(roles.includes('MASTER'));
        }
    }, [userStore.roles]);
    return (
        <div id="settingsPage" className="settings">
            <BreadCrumbs
                breadcrumbs={[
                    { to: '/', name: t('search') },
                    { to: '/admin-settings', name: t('components.navbar.settings') },
                ]}
            />
            <div className="card">
                <div className="title">
                    <SVGLogo height={50} width="auto" />
                </div>
                <div className="btnContainer">
                    <a className="btn btn-enabled" type="button" onClick={workInProgress}>
                        <img className="btnIcon" src={DocumentIcon} alt="Document Icon" />
                        {t('pages.admin-settings-page.UserManagement')}
                    </a>
                    {isMasterUser && (
                        <a
                            className="btn btn-enabled"
                            type="button"
                            onClick={() => history.push('/admin-settings/register-client')}
                        >
                            <img className="btnIcon" src={PlusInCircleIcon} alt="Document Icon" />
                            {t('pages.admin-settings-page.RegisterClient')}
                        </a>
                    )}
                    {isMasterUser && (
                        <a
                            className="btn btn-enabled"
                            type="button"
                            onClick={() => history.push('/admin-settings/register-master-user')}
                        >
                            <img className="btnIcon" src={MasterUserIcon} alt="Document Icon" />
                            {t('pages.admin-settings-page.RegisterMasterUser')}
                        </a>
                    )}
                    <a className="btn btn-enabled" type="button" onClick={workInProgress}>
                        <img className="btnIcon" src={PlusIcon} alt="Document Icon" />
                        {t('pages.admin-settings-page.RegisterNewUser')}
                    </a>
                </div>
            </div>
        </div>
    );
};

export default SettingsPage;
