import React from 'react';
import StarRating from 'react-rating';
import './RatingGroup.scss';
import { useTranslation } from 'react-i18next';
import { IArticle } from '../../services/search.service';
import { Immutable } from 'react-service-locator/dist/types/immutable';

const RatingGroup: React.FC<{ article: Immutable<IArticle>; children?: React.ReactNode }> = (props: {
    article: Immutable<IArticle>;
    children?: React.ReactNode;
}) => {
    const { t } = useTranslation();
    const isGoodArticle = props.article.score > 2;
    return (
        <div>
            <div className="rating-group">
                <div className="total">
                    <h3>{t('components.rating-group.overall-score')}:</h3>
                    <StarRating
                        initialRating={props.article.score}
                        readonly={true}
                        fractions={4}
                        emptySymbol={<span className="material-icons">star_outlined</span>}
                        fullSymbol={<span className="material-icons">star</span>}
                    />
                </div>
                <p className="body body-normal">{t('components.rating-group.based-on')}:</p>
                {props.article.indicators.map((c, i) => (
                    <label key={`indicator-${props.article.pmid}-${i}`} className="indicator">
                        {isGoodArticle ? (
                            <span className="material-icons-outlined positive">add_circle</span>
                        ) : (
                            <span className="material-icons-outlined negative">remove_circle</span>
                        )}
                        <p className="body body-normal">{c}</p>
                    </label>
                ))}
                {props.article.isFunded && (
                    <div className="industry-payed">
                        <span className="material-icons">error</span>
                        <p className="body body-normal">{t('components.rating-group.industry-payed')}</p>
                    </div>
                )}
            </div>
            {props.children}
        </div>
    );
};

export default RatingGroup;
