import CancelablePromise, { cancelable } from 'cancelable-promise';
import { ceil, divide } from 'lodash';
import { Inject, Service, StatefulService } from 'react-service-locator';
import { toEnglish } from '../translation';
import { jsonDateParser } from 'json-date-parser';
import { LifestyleInterventionService } from './lifestyle-intervention.service';
import { DiseaseService } from './disease.service';
import delay from 'delay';
import StatusCode from 'status-code-enum';
import { refreshTokensIfNeeded } from '../axios/axiosAuthInterceptor';

export type SortEnum = 'score_asc' | 'score_desc';

type IFilters = {
    minAge: number;
    maxAge: number;
    gender?: 'male' | 'female';
    studyDesign?: 'meta-analysis' | 'RCT';
    minPublicationYear: number;
    maxPublicationYear: number;
    studyGroupSize?: number;
    studyPeriod?: number;
    amountOfCitations?: number;
};
interface IPaginatedResult {
    currentPageNumber: number;
    currentPage: IPage;
    previousPages: IPage[];
    nextPages: IPage[];
    totalResults: number;
    totalPages: number;
}

type IPage = IArticle[];

export interface IArticle {
    pmid: string;
    dois: string[];
    title: string;
    score: number;
    keywords: string[];
    publicationDate?: Date;
    indicators: string[];
    isFunded: boolean;
    researchPopulation?: string;
}

type ISearchCriteria = {
    keywords: string[];
    lifestyleInterventions: string[];
};

type ISearchQuery = {
    criteria: ISearchCriteria;
    filters: Partial<IFilters>;
    sort: SortEnum;
    batchSize: number;
    firstPage: number;
    endPage: number;
};

type IPagination = {
    TotalCount: number;
};

@Service()
export class SearchService extends StatefulService<{
    disease?: string;
    symptoms: string[];
    lifestyleInterventionGroup?: string;
    lifestyleInterventions: string[];
    lifestyleInterventionGroupAll: boolean;
    filters: IFilters;
    isLoading: boolean;
    result?: IPaginatedResult;
    sort: SortEnum;
}> {
    @Inject(LifestyleInterventionService)
    private readonly lifestyleInterventionService!: LifestyleInterventionService;
    @Inject(DiseaseService)
    private readonly diseaseService!: DiseaseService;
    public static MIN_AGE = 0;
    public static MAX_AGE = 120;
    public static MIN_PUBLICATION_YEAR = 1901;
    public static get MAX_PUBLICATION_YEAR() {
        return new Date().getFullYear();
    }
    private static MAX_AMOUNT_ADITIONAL_PAGES = 10;
    private static BATCH_SIZE = 8;
    private tasks: CancelablePromise[];

    //retrieve previous pages
    private async p_callable_task(previousPages: IPage[], pageNumber: number) {
        if (!this.state.result) {
            return;
        }
        if (previousPages.length < SearchService.MAX_AMOUNT_ADITIONAL_PAGES && pageNumber - previousPages.length != 1) {
            const start =
                pageNumber - SearchService.MAX_AMOUNT_ADITIONAL_PAGES - 1 > 0
                    ? pageNumber - SearchService.MAX_AMOUNT_ADITIONAL_PAGES - 1
                    : 0;
            const end = pageNumber - previousPages.length - 2 > 0 ? pageNumber - previousPages.length - 2 : 0;
            const response = await this.loadPages(start, end);
            const headers = response.headers.get('X-Pagination');
            const pagination: IPagination = JSON.parse(headers as string);
            const totalPages = ceil(divide(pagination.TotalCount, SearchService.BATCH_SIZE)),
                totalResults = pagination.TotalCount;
            const articles =
                response.status === StatusCode.SuccessNoContent
                    ? []
                    : (JSON.parse(JSON.stringify(await response.json()), jsonDateParser) as IPage[]);
            previousPages.unshift(...articles);
            return [previousPages, totalPages, totalResults];
        }
    }

    //retrieve next pages
    private async n_callable_task(nextPages: IPage[], pageNumber: number) {
        if (!this.state.result) {
            return;
        }
        if (
            nextPages.length < SearchService.MAX_AMOUNT_ADITIONAL_PAGES &&
            pageNumber + nextPages.length < this.state.result.totalPages
        ) {
            const start = pageNumber + nextPages.length;
            const end =
                pageNumber + SearchService.MAX_AMOUNT_ADITIONAL_PAGES > this.state.result.totalPages
                    ? this.state.result.totalPages - 1
                    : pageNumber + SearchService.MAX_AMOUNT_ADITIONAL_PAGES - 1;
            const response = await this.loadPages(start, end);
            const headers = response.headers.get('X-Pagination');
            const pagination: IPagination = JSON.parse(headers as string);
            const totalPages = ceil(divide(pagination.TotalCount, SearchService.BATCH_SIZE)),
                totalResults = pagination.TotalCount;
            const articles =
                response.status === StatusCode.SuccessNoContent
                    ? []
                    : (JSON.parse(JSON.stringify(await response.json()), jsonDateParser) as IPage[]);
            nextPages.push(...articles);
            return [nextPages, totalPages, totalResults];
        }
    }

    public constructor() {
        super({
            filters: {
                minAge: SearchService.MIN_AGE,
                maxAge: SearchService.MAX_AGE,
                minPublicationYear: 2000,
                maxPublicationYear: SearchService.MAX_PUBLICATION_YEAR,
            },
            sort: 'score_desc',
            lifestyleInterventions: [],
            symptoms: [],
            lifestyleInterventionGroupAll: false,
            isLoading: false,
        });
        this.tasks = [];
    }

    private async loadPages(firstPage: number, lastPage: number) {
        const english_keywords = await Promise.all(
            ['diabetes', ...this.state.symptoms].map(
                async (k: string) => ((await toEnglish(k)).match('^.*?(?=&|$)') as string[])[0],
            ),
        );
        // TODO re-enable this whenever api can split lifestyle interventions
        const lifestyleInterventions =
            /*[...this.state.lifestyleInterventions];*/ this.state.lifestyleInterventions.flatMap((c) => c.split(' '));
        const criteria: ISearchQuery = {
            criteria: {
                keywords: english_keywords,
                lifestyleInterventions:
                    lifestyleInterventions.length === 0
                        ? [
                              `${this.state.lifestyleInterventionGroup![0].toUpperCase()}${this.state.lifestyleInterventionGroup!.substring(
                                  1,
                              )}_All`,
                          ]
                        : lifestyleInterventions,
            },
            filters: {
                ...this.state.filters,
                minAge:
                    this.state.filters.minAge === SearchService.MIN_AGE &&
                    this.state.filters.maxAge === SearchService.MAX_AGE
                        ? undefined
                        : this.state.filters.minAge,
                maxAge:
                    this.state.filters.minAge === SearchService.MIN_AGE &&
                    this.state.filters.maxAge === SearchService.MAX_AGE
                        ? undefined
                        : this.state.filters.maxAge,
                minPublicationYear:
                    this.state.filters.minPublicationYear === SearchService.MIN_PUBLICATION_YEAR &&
                    this.state.filters.maxPublicationYear === SearchService.MAX_PUBLICATION_YEAR
                        ? undefined
                        : this.state.filters.minPublicationYear,
                maxPublicationYear:
                    this.state.filters.minPublicationYear === SearchService.MIN_PUBLICATION_YEAR &&
                    this.state.filters.maxPublicationYear === SearchService.MAX_PUBLICATION_YEAR
                        ? undefined
                        : this.state.filters.maxPublicationYear,
            },
            sort: this.state.sort,
            batchSize: SearchService.BATCH_SIZE,
            firstPage: firstPage,
            endPage: lastPage,
        };
        await refreshTokensIfNeeded();
        const accessToken = localStorage.getItem('accessToken');
        const date = new Date().toUTCString();
        return fetch(process.env.REACT_APP_BASE_API_URI + '/Document/get', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'x-ms-date': date,
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(criteria),
        });
    }

    public toggleLifestyleInterventionGroupAll(lifestyleInterventionGroupAll: boolean) {
        this.setState({ lifestyleInterventionGroupAll });
        if (lifestyleInterventionGroupAll && this.state.lifestyleInterventions.length !== 0) {
            this.setLifestyleInterventions([]);
        }
    }

    public setLifestyleInterventions(lifestyleInterventions: string[]) {
        this.setState({ lifestyleInterventions });
        if (lifestyleInterventions.length !== 0 && this.state.lifestyleInterventionGroupAll) {
            this.toggleLifestyleInterventionGroupAll(false);
        }
    }

    public setLifestyleInterventionGroup(lifestyleInterventionGroup?: string) {
        this.setLifestyleInterventions([]);
        this.toggleLifestyleInterventionGroupAll(false);
        this.lifestyleInterventionService.setGroup(lifestyleInterventionGroup);
        this.setState({ lifestyleInterventionGroup });
    }

    public setSymptoms(symptoms: string[]) {
        this.setState({ symptoms });
    }

    public setDisease(disease?: string) {
        this.diseaseService.setDisease(disease);
        this.setSymptoms([]);
        this.setLifestyleInterventionGroup();
        this.setState({ disease });
    }

    public setFilters(filters: IFilters) {
        this.setState({ filters });
        this.tasks.forEach((task) => task.cancel());
        this.tasks = [];
        const task = cancelable(delay(700));
        task.then(() => this.search_async());
        this.tasks = [task];
    }

    public setSort(sort: SortEnum) {
        this.setState({ sort });
        this.search();
    }

    public clearResult() {
        this.setState({ result: undefined });
    }

    public gotoPage(pageNumber: number) {
        if (!this.state.result || pageNumber === this.state.result.currentPageNumber) {
            return;
        }
        this.tasks.forEach((task) => task.cancel());
        this.tasks = [];
        const pageDifference = pageNumber - this.state.result.currentPageNumber;
        const absPageDifference = Math.abs(pageDifference);
        const isNext = pageDifference === absPageDifference;
        const previousPages: IPage[] = JSON.parse(JSON.stringify(this.state.result.previousPages), jsonDateParser);
        const nextPages: IPage[] = JSON.parse(JSON.stringify(this.state.result.nextPages), jsonDateParser);
        let currentPage: IPage = JSON.parse(JSON.stringify(this.state.result.currentPage), jsonDateParser);
        if (isNext) {
            if (previousPages.length > SearchService.MAX_AMOUNT_ADITIONAL_PAGES - absPageDifference) {
                previousPages.splice(0, absPageDifference);
            }
            previousPages.push(currentPage);
            if (absPageDifference !== 1) {
                previousPages.push(...nextPages.splice(0, pageDifference - 1));
            }
            currentPage = nextPages.shift() ?? [];
        } else {
            if (nextPages.length > SearchService.MAX_AMOUNT_ADITIONAL_PAGES - absPageDifference) {
                nextPages.splice(pageDifference, absPageDifference);
            }
            nextPages.unshift(currentPage);
            if (absPageDifference !== 1) {
                nextPages.unshift(...previousPages.splice(pageDifference + 1));
            }
            currentPage = previousPages.pop() ?? [];
        }
        const task = cancelable(delay(700));
        task.then(() => {
            const p_task = cancelable(this.p_callable_task(previousPages, pageNumber));
            const n_task = cancelable(this.n_callable_task(nextPages, pageNumber));
            n_task.then((c) => {
                if (c)
                    this.setState({
                        result: {
                            ...JSON.parse(JSON.stringify(this.state.result), jsonDateParser),
                            totalPages: (c[1] as number) ?? 0,
                            totalResults: (c[2] as number) ?? 0,
                            nextPages: (c[0] as IPage[]) ?? 0,
                        },
                    });
            });
            p_task.then((c) => {
                if (c)
                    this.setState({
                        result: {
                            ...JSON.parse(JSON.stringify(this.state.result), jsonDateParser),
                            totalPages: (c[1] as number) ?? 0,
                            totalResults: (c[2] as number) ?? 0,
                            previousPages: (c[0] as IPage[]) ?? 0,
                        },
                    });
            });
            this.tasks = [p_task, n_task];
        });
        this.setState({
            result: {
                ...this.state.result,
                currentPage: currentPage,
                nextPages: nextPages,
                previousPages: previousPages,
                currentPageNumber: pageNumber,
            },
        });
        this.tasks = [task];
    }

    private async search_async() {
        const response = await this.loadPages(0, SearchService.MAX_AMOUNT_ADITIONAL_PAGES);
        if (response.status === StatusCode.SuccessNoContent) {
            const result: IPaginatedResult = {
                currentPageNumber: 1,
                previousPages: [],
                currentPage: [],
                nextPages: [],
                totalPages: 0,
                totalResults: 0,
            };
            this.setState({ result, isLoading: false });
            return;
        }
        const articles = JSON.parse(JSON.stringify(await response.json()), jsonDateParser) as IPage[];
        const headers = response.headers.get('X-Pagination');
        const pagination: IPagination = JSON.parse(headers as string);
        const total_pages = ceil(divide(pagination.TotalCount, SearchService.BATCH_SIZE)),
            total_results = pagination.TotalCount;
        const result: IPaginatedResult = {
            currentPageNumber: 1,
            previousPages: [],
            currentPage: articles.shift() ?? [],
            nextPages: articles,
            totalPages: total_pages,
            totalResults: total_results,
        };
        this.setState({ result, isLoading: false });
    }

    public search() {
        this.setState({ isLoading: true });
        this.search_async();
    }

    public get shouldRedirect() {
        return (
            !this.state.disease ||
            (!this.state.lifestyleInterventionGroupAll && this.state.lifestyleInterventions.length === 0)
        );
    }
}
