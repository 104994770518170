import React, { memo, useEffect, useRef, useState } from 'react';
import Diet from '../../images/icons/diet.svg';
import Relax from '../../images/icons/relaxation.svg';
import Sleep from '../../images/icons/sleeping.svg';
import Movement from '../../images/icons/excercise.svg';
import DietSelected from '../../images/icons/diet-selected.svg';
import RelaxSelected from '../../images/icons/relaxation-selected.svg';
import SleepSelected from '../../images/icons/sleeping-selected.svg';
import MovementSelected from '../../images/icons/excercise-selected.svg';
import LifestyleInterventionButton from '../LifestyleInterventionButton/LifestyleInterventionButton';
import './SearchLifestyleIntervention.scss';

const SearchLifestyleInterventionBase: React.FC = () => {
    const MovementRef = useRef<HTMLDivElement>(null);
    const DietRef = useRef<HTMLDivElement>(null);
    const SleepRef = useRef<HTMLDivElement>(null);
    const RelaxRef = useRef<HTMLDivElement>(null);

    const refs: React.MutableRefObject<HTMLDivElement | null>[] = [MovementRef, DietRef, SleepRef, RelaxRef];

    const [clickedOutside, setClickedOutside] = useState(false);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: MouseEvent) {
            if (refs.every((ref) => ref?.current && !ref?.current.contains(event.target as Node))) {
                setClickedOutside(true);
                Array.from(document.getElementsByClassName('collapsible')).forEach((c) => {
                    c.classList.remove('active');
                });
            }
        }
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [MovementRef, DietRef, SleepRef, RelaxRef]);
    return (
        <div className="SearchLifestyleIntervention">
            <LifestyleInterventionButton
                name="exercise"
                image={Movement}
                selectedImage={MovementSelected}
                ref={MovementRef}
                clickedOutside={clickedOutside}
                setClickedOutside={setClickedOutside}
            />
            <LifestyleInterventionButton
                name="nutrition"
                image={Diet}
                selectedImage={DietSelected}
                ref={DietRef}
                clickedOutside={clickedOutside}
                setClickedOutside={setClickedOutside}
            />
            <LifestyleInterventionButton
                name="sleep"
                image={Sleep}
                selectedImage={SleepSelected}
                ref={SleepRef}
                clickedOutside={clickedOutside}
                setClickedOutside={setClickedOutside}
            />
            <LifestyleInterventionButton
                name="relaxation"
                image={Relax}
                selectedImage={RelaxSelected}
                ref={RelaxRef}
                clickedOutside={clickedOutside}
                setClickedOutside={setClickedOutside}
            />
        </div>
    );
};

const SearchLifestyleIntervention = memo(SearchLifestyleInterventionBase);

export default SearchLifestyleIntervention;
