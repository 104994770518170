import { includes, round } from 'lodash';
import React, { FC, useEffect } from 'react';
import { useService } from 'react-service-locator';
import { SearchService } from '../../services/search.service';
import './FilterSection.scss';
import Slider from 'rc-slider';
import { useTranslation } from 'react-i18next';

const calculatePaddingPercentageLeft = (min: number, max: number, vMin: number, vMax: number) => {
    const range = max - min;
    const rangeToLeft = vMin - min;
    const rangeToRight = vMax - min;
    const rangeToAvg = (rangeToLeft + rangeToRight) / 2;
    return (rangeToAvg / range) * 100;
};

const FilterSection: FC = () => {
    const { t } = useTranslation();
    const searchService = useService(SearchService);
    const selectGender = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as 'male' | 'female';
        if (!value) {
            return;
        }
        const currentFilters = { ...searchService.state.filters };
        if (!event.target.checked) {
            currentFilters.gender = undefined;
        } else {
            currentFilters.gender = value;
        }
        searchService.setFilters(currentFilters);
    };
    const selectStudyDesign = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as 'meta-analysis' | 'RCT';
        if (!value) {
            return;
        }
        const currentFilters = { ...searchService.state.filters };
        if (!event.target.checked) {
            currentFilters.studyDesign = undefined;
        } else {
            currentFilters.studyDesign = value;
        }
        searchService.setFilters(currentFilters);
    };
    const selectStudySize = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (!value) {
            return;
        }
        const currentFilters = { ...searchService.state.filters };
        if (!event.target.checked) {
            currentFilters.studyGroupSize = undefined;
        } else {
            currentFilters.studyGroupSize = value;
        }
        searchService.setFilters(currentFilters);
    };
    const selectStudyPeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (!value) {
            return;
        }
        const currentFilters = { ...searchService.state.filters };
        if (!event.target.checked) {
            currentFilters.studyPeriod = undefined;
        } else {
            currentFilters.studyPeriod = value;
        }
        searchService.setFilters(currentFilters);
    };
    const selectAmountOfCitations = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (!value) {
            return;
        }
        const currentFilters = { ...searchService.state.filters };
        if (!event.target.checked) {
            currentFilters.amountOfCitations = undefined;
        } else {
            currentFilters.amountOfCitations = value;
        }
        searchService.setFilters(currentFilters);
    };
    const selectAge = (age: number[]) => {
        const sliderAgeLabel = document.getElementById('slider-age-label');
        if (!sliderAgeLabel) {
            return;
        }
        const minAge = age[0];
        const maxAge = age[1];
        const currentFilters = { ...searchService.state.filters };
        currentFilters.minAge = minAge;
        currentFilters.maxAge = maxAge;
        searchService.setFilters(currentFilters);
    };
    const selectPublicationYear = (publicationYears: number[]) => {
        const sliderPublicationYearLabel = document.getElementById('slider-publication-year-label');
        if (!sliderPublicationYearLabel) {
            return;
        }
        const minPublicationYear =
            publicationYears[0] > 2000
                ? round(((publicationYears[0] - 2000) / 99) * (SearchService.MAX_PUBLICATION_YEAR - 2000) + 2000)
                : publicationYears[0];
        const maxPublicationYear =
            publicationYears[1] > 2000
                ? round(((publicationYears[1] - 2000) / 99) * (SearchService.MAX_PUBLICATION_YEAR - 2000) + 2000)
                : publicationYears[1];
        const currentFilters = { ...searchService.state.filters };
        currentFilters.minPublicationYear = minPublicationYear;
        currentFilters.maxPublicationYear = maxPublicationYear;
        searchService.setFilters(currentFilters);
    };
    useEffect(() => {
        const sliderAgeLabel = document.getElementById('slider-age-label');
        const componentWidth = Array.from(sliderAgeLabel?.children ?? []).at(0)?.clientWidth;
        if (sliderAgeLabel && componentWidth) {
            const paddingLeft = calculatePaddingPercentageLeft(
                SearchService.MIN_AGE,
                SearchService.MAX_AGE,
                searchService.state.filters.minAge,
                searchService.state.filters.maxAge,
            );
            const paddingRight = 100 - paddingLeft;
            const componentWidthLeft = (paddingLeft / 100) * componentWidth;
            const componentWidthRight = (paddingRight / 100) * componentWidth;
            sliderAgeLabel.style.padding = `0px calc(${paddingRight}% - ${componentWidthRight}px) 0px calc(${paddingLeft}% - ${componentWidthLeft}px)`;
        }
    }, [searchService.state.filters.minAge, searchService.state.filters.maxAge]);
    useEffect(() => {
        const sliderPublicationYearLabel = document.getElementById('slider-publication-year-label');
        const componentWidth = Array.from(sliderPublicationYearLabel?.children ?? []).at(0)?.clientWidth;
        if (sliderPublicationYearLabel && componentWidth) {
            const paddingLeft = calculatePaddingPercentageLeft(
                SearchService.MIN_PUBLICATION_YEAR,
                2099,
                searchService.state.filters.minPublicationYear > 2000
                    ? round(
                          ((searchService.state.filters.minPublicationYear - 2000) /
                              (SearchService.MAX_PUBLICATION_YEAR - 2000)) *
                              99 +
                              2000,
                      )
                    : searchService.state.filters.minPublicationYear,
                searchService.state.filters.maxPublicationYear > 2000
                    ? round(
                          ((searchService.state.filters.maxPublicationYear - 2000) /
                              (SearchService.MAX_PUBLICATION_YEAR - 2000)) *
                              99 +
                              2000,
                      )
                    : searchService.state.filters.maxPublicationYear,
            );
            const paddingRight = 100 - paddingLeft;
            const componentWidthLeft = (paddingLeft / 100) * componentWidth;
            const componentWidthRight = (paddingRight / 100) * componentWidth;
            sliderPublicationYearLabel.style.padding = `0px calc(${paddingRight}% - ${componentWidthRight}px) 0px calc(${paddingLeft}% - ${componentWidthLeft}px)`;
        }
    }, [searchService.state.filters.minPublicationYear, searchService.state.filters.maxPublicationYear]);
    return (
        <div className="FilterSection">
            <h3>{t('components.filters.filters')}</h3>
            <p className="body body-normal">{t('components.filters.gender')}</p>
            <div>
                <label className="mark">
                    <input
                        type="checkbox"
                        value="female"
                        name={'checkbox-filter-gender'}
                        onChange={selectGender}
                        checked={searchService.state.filters.gender === 'female'}
                    />

                    <span className="dot" />
                    <p className="body body-small">{t('components.filters.female')}</p>
                </label>
                <label className="mark">
                    <input
                        type="checkbox"
                        value="male"
                        name={'checkbox-filter-gender'}
                        onChange={selectGender}
                        checked={searchService.state.filters.gender === 'male'}
                    />

                    <span className="dot" />
                    <p className="body body-small">{t('components.filters.male')}</p>
                </label>
            </div>
            <p className="body body-normal">{t('components.filters.age')}</p>
            <div className="slider">
                <div id="slider-age" className="slider-wrapper">
                    <p className="body body-small">{SearchService.MIN_AGE}</p>
                    <Slider
                        range
                        allowCross={false}
                        min={SearchService.MIN_AGE}
                        max={SearchService.MAX_AGE}
                        value={[searchService.state.filters.minAge, searchService.state.filters.maxAge]}
                        onChange={(v) => selectAge(v as number[])}
                    />
                    <p className="body body-small">{SearchService.MAX_AGE}</p>
                </div>
                <label id="slider-age-label" htmlFor="#slider-age">
                    <p className="body body-small">{`${searchService.state.filters.minAge} - ${searchService.state.filters.maxAge}`}</p>
                </label>
            </div>
            <p className="body body-normal">{t('components.filters.study-design')}</p>
            <div>
                <label className="mark">
                    <input
                        type="checkbox"
                        value="meta-analysis"
                        name={'checkbox-filter-study-design'}
                        onChange={selectStudyDesign}
                        checked={searchService.state.filters.studyDesign === 'meta-analysis'}
                    />

                    <span className="dot" />
                    <p className="body body-small">{t('components.filters.meta-analysis')}</p>
                </label>
                <label className="mark">
                    <input
                        type="checkbox"
                        value="RCT"
                        name={'checkbox-filter-study-design'}
                        onChange={selectStudyDesign}
                        checked={searchService.state.filters.studyDesign === 'RCT'}
                    />

                    <span className="dot" />
                    <p className="body body-small">{t('components.filters.rct')}</p>
                </label>
            </div>
            <p className="body body-normal">{t('components.filters.publication-year')}</p>
            <div className="slider">
                <div id="slider-publication-year" className="slider-wrapper">
                    <p className="body body-small">{SearchService.MIN_PUBLICATION_YEAR}</p>
                    <Slider
                        range
                        allowCross={false}
                        min={SearchService.MIN_PUBLICATION_YEAR}
                        max={2099}
                        value={[
                            searchService.state.filters.minPublicationYear > 2000
                                ? round(
                                      ((searchService.state.filters.minPublicationYear - 2000) /
                                          (SearchService.MAX_PUBLICATION_YEAR - 2000)) *
                                          99 +
                                          2000,
                                  )
                                : searchService.state.filters.minPublicationYear,
                            searchService.state.filters.maxPublicationYear > 2000
                                ? round(
                                      ((searchService.state.filters.maxPublicationYear - 2000) /
                                          (SearchService.MAX_PUBLICATION_YEAR - 2000)) *
                                          99 +
                                          2000,
                                  )
                                : searchService.state.filters.maxPublicationYear,
                        ]}
                        onChange={(v) => selectPublicationYear(v as number[])}
                    />
                    <p className="body body-small">{t('components.filters.present')}</p>
                </div>
                <label id="slider-publication-year-label" htmlFor="#slider-publication-year">
                    <p className="body body-small">{`${searchService.state.filters.minPublicationYear} - ${
                        searchService.state.filters.maxPublicationYear === SearchService.MAX_PUBLICATION_YEAR
                            ? t('components.filters.present')
                            : searchService.state.filters.maxPublicationYear
                    }`}</p>
                </label>
            </div>
            <p className="body body-normal">{t('components.filters.study-group-size')}</p>
            <div>
                <label className="mark">
                    <input
                        type="checkbox"
                        value={500}
                        name={'checkbox-filter-study-group-size'}
                        onChange={selectStudySize}
                        checked={searchService.state.filters.studyGroupSize === 500}
                    />

                    <span className="dot" />
                    <p className="body body-small">{t('components.filters.more-than')} 500</p>
                </label>
                <label className="mark">
                    <input
                        type="checkbox"
                        value={2000}
                        name={'checkbox-filter-study-group-size'}
                        onChange={selectStudySize}
                        checked={searchService.state.filters.studyGroupSize === 2000}
                    />

                    <span className="dot" />
                    <p className="body body-small">{t('components.filters.more-than')} 2.000</p>
                </label>
                <label className="mark">
                    <input
                        type="checkbox"
                        value={10000}
                        name={'checkbox-filter-study-group-size'}
                        onChange={selectStudySize}
                        checked={searchService.state.filters.studyGroupSize === 10000}
                    />

                    <span className="dot" />
                    <p className="body body-small">{t('components.filters.more-than')} 10.000</p>
                </label>
            </div>
            <p className="body body-normal">{t('components.filters.study-period')}</p>
            <div>
                <label className="mark">
                    <input
                        type="checkbox"
                        value={1}
                        name={'checkbox-filter-study-period'}
                        onChange={selectStudyPeriod}
                        checked={searchService.state.filters.studyPeriod === 1}
                    />

                    <span className="dot" />
                    <p className="body body-small">
                        {t('components.filters.more-than')} 1 {t('components.filters.year')}
                    </p>
                </label>
                <label className="mark">
                    <input
                        type="checkbox"
                        value={2}
                        name={'checkbox-filter-study-period'}
                        onChange={selectStudyPeriod}
                        checked={searchService.state.filters.studyPeriod === 2}
                    />

                    <span className="dot" />
                    <p className="body body-small">
                        {t('components.filters.more-than')} 2 {t('components.filters.year')}
                    </p>
                </label>
                <label className="mark">
                    <input
                        type="checkbox"
                        value={5}
                        name={'checkbox-filter-study-period'}
                        onChange={selectStudyPeriod}
                        checked={searchService.state.filters.studyPeriod === 5}
                    />

                    <span className="dot" />
                    <p className="body body-small">
                        {t('components.filters.more-than')} 5 {t('components.filters.year')}
                    </p>
                </label>
            </div>
            <p className="body body-normal">{t('components.filters.amount-of-citations')}</p>
            <div>
                <label className="mark">
                    <input
                        type="checkbox"
                        value={50}
                        name={'checkbox-filter-amount-of-citations'}
                        onChange={selectAmountOfCitations}
                        checked={searchService.state.filters.amountOfCitations === 50}
                    />

                    <span className="dot" />
                    <p className="body body-small">{t('components.filters.more-than')} 50</p>
                </label>
            </div>
        </div>
    );
};

export default FilterSection;
