import { uniq } from 'lodash';
import { Service, StatefulService } from 'react-service-locator';
import { StatusCode } from 'status-code-enum';
import axiosInstance from '../axios/axiosAuthInterceptor';

type ILifestyleInterventionGroup = {
    type: string;
    values: string[];
};

type ILifestyleInterventionValue = {
    id: number;
    type: string;
    value: string;
};

type SaveLifestyleInterventionBody = {
    value: string;
    type: string;
};

@Service()
export class LifestyleInterventionService extends StatefulService<{
    lifestyleInterventions?: string[];
}> {
    private lifestyleInterventionGroups: ILifestyleInterventionGroup[];

    constructor() {
        super({});
        this.lifestyleInterventionGroups = [];
    }

    async fetchLifestyleInterventions() {
        try {
            const response = await axiosInstance.get('/LifestyleIntervention/all');

            if (response.status === StatusCode.SuccessNoContent) {
                return [];
            }

            const lifestyleInterventionValues = response.data as ILifestyleInterventionValue[];
            const groups = uniq(lifestyleInterventionValues.map((c) => c.type));
            this.lifestyleInterventionGroups = groups.map((c) => {
                const lifestyleInterventionGroup: ILifestyleInterventionGroup = {
                    type: c.toLowerCase(),
                    values: lifestyleInterventionValues.filter((d) => d.type === c).map((e) => e.value),
                };
                return lifestyleInterventionGroup;
            });
        } catch (error) {
            console.error('Error fetching lifestyle interventions:', error);
        }
    }

    async saveLifestyleIntervention(intervention: string, group: string) {
        try {
            const saveLifestyleInterventionBody: SaveLifestyleInterventionBody = {
                value: intervention,
                type: `${group[0].toUpperCase()}${group.substring(1)}`,
            };
            await axiosInstance.post('LifestyleIntervention/save', saveLifestyleInterventionBody);
        } catch (error) {
            console.error('Error saving lifestyle intervention:', error);
        }
    }

    setGroup(group?: string) {
        const lifestyleInterventions = this.lifestyleInterventionGroups.find(
            (c) => c.type === group?.toLocaleLowerCase(),
        )?.values;
        this.setState({ lifestyleInterventions });
    }
}
