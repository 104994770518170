import NederlandsFlagIcon from '../../images/icons/Flags/Nederlands.svg';
import AmericanFlagIcon from '../../images/icons/Flags/Flag_of_the_United_States.svg';

export const LanguageEnum = {
    0: 'en',
    1: 'nl',
};

export function getKeyByValue<T>(object: { [key: string]: T }, value: T): string | undefined {
    return Object.keys(object).find((key) => object[key] === value);
}

export const LanguageValue = {
    ENGLISH: 'English',
    DUTCH: 'Nederlands',
};

export const languageWithFlagOptions = [
    {
        key: LanguageEnum['0'],
        text: 'English',
        value: LanguageValue.ENGLISH,
        image: AmericanFlagIcon,
    },
    {
        key: LanguageEnum['1'],
        text: 'Nederlands',
        value: LanguageValue.DUTCH,
        image: NederlandsFlagIcon,
    },
];
