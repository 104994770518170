import { Redirect } from 'react-router';
import Article from '../../components/Article/Article';
import Pagination from '../../components/Pagination/Pagination';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './ResultPage.scss';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import { MetroSpinner } from 'react-spinners-kit';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronLeft } from '../../images/icons/chevron-left.svg';
import { ReactComponent as ChevronDown } from '../../images/icons/chevron-down.svg';
import { useService } from 'react-service-locator';
import { SearchService, SortEnum } from '../../services/search.service';
import FilterSection from '../../components/FilterSection/FilterSection';

const getNumberWithCommas = (value: number) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const ResultPage: React.FC = () => {
    const { t } = useTranslation();
    const searchService = useService(SearchService);
    const [isScrolled, toggleScrolled] = useState(true);
    const onScroll = () => {
        toggleScrolled(window.scrollY <= 15);
    };
    const sortOptions: { label: string; value: SortEnum }[] = [
        {
            label: t('pages.result-page.sort.score-decrease') as string,
            value: 'score_desc',
        },
        {
            label: t('pages.result-page.sort.score-increase') as string,
            value: 'score_asc',
        },
    ];
    useEffect(() => {
        window.addEventListener('scroll', onScroll);
    }, []);
    const lifestyleInterventionsAll = [
        ...searchService.state.symptoms,
        searchService.state.lifestyleInterventionGroup,
        ...searchService.state.lifestyleInterventions,
    ];
    if (searchService.shouldRedirect) {
        return <Redirect to="/" />;
    }
    if (!searchService.state.result || searchService.state.isLoading) {
        return (
            <div id="resultpage" className="result wrapper">
                <MetroSpinner size={150} color="#3a58a1" />
            </div>
        );
    }
    return (
        <div id="resultpage" className="result">
            {isScrolled ? (
                <>
                    <BreadCrumbs
                        breadcrumbs={[
                            { to: '/', name: t('search') },
                            {
                                to: '/results',
                                name: `${searchService.state.disease} ${t('search-results').toLocaleLowerCase()}`,
                            },
                        ]}
                    />
                    <div className="card">
                        <Link className="back-button" to="/">
                            <ChevronLeft height={48} width={48} />
                        </Link>
                        <div>
                            <div>
                                <h1>{searchService.state.disease}</h1>
                                <p id="filters" className="body body-big">
                                    {lifestyleInterventionsAll.map((v, i, a) =>
                                        a.length - 1 === i ? (
                                            v
                                        ) : (
                                            <>{v}&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;</>
                                        ),
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className="card">
                    <BreadCrumbs
                        breadcrumbs={[
                            { to: '/', name: t('search') },
                            {
                                to: '/results',
                                name: `${searchService.state.disease} ${t('search-results').toLocaleLowerCase()}`,
                            },
                        ]}
                    />
                    <Link className="back-button" to="/">
                        <ChevronLeft height={32} width={32} />
                    </Link>
                    <div>
                        <div>
                            <h2>{searchService.state.disease}</h2>
                            <p id="filters" className="body body-big">
                                {lifestyleInterventionsAll.map((v, i, a) =>
                                    a.length - 1 === i ? v : <>{v}&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;</>,
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            )}

            <div className="wrapper">
                <FilterSection />
                {searchService.state.result.totalResults === 0 ? (
                    <div className="card" tabIndex={-1}>
                        <div className="no-results">
                            <h3>{t('pages.result-page.no-results-text')}</h3>
                            <div className="btn-wrapper">
                                <Link className="body body-big btn" to="/">
                                    {t('ok')}
                                </Link>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="card">
                        <div className="info">
                            <p className="body body-big">
                                {getNumberWithCommas(searchService.state.result.totalResults)}{' '}
                                {t('pages.result-page.results').toLocaleLowerCase()}
                            </p>
                            <Select
                                className="body body-big select"
                                value={sortOptions.find((c) => c.value === searchService.state.sort)}
                                options={sortOptions}
                                onChange={(v) => searchService.setSort(v?.value as SortEnum)}
                                components={{ DownChevron: ChevronDown }}
                            />{' '}
                        </div>
                        {searchService.state.result?.currentPage?.map((c, i) => (
                            <Article key={'article-' + i} article={c} />
                        ))}
                        <div>
                            <Pagination />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ResultPage;
