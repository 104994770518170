import axios from 'axios';
import userStore from '../MobXStorage/UserStore';
import { useExtractRoles } from '../hooks/UserHooks';

let isRefreshing = false;

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_API_URI,
});

const addTokenToHeaders = (accessToken: string) => {
    if (accessToken) {
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    } else {
        delete axiosInstance.defaults.headers.common['Authorization'];
    }
};

export const initOnRefresh = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const accessToken = localStorage.getItem('accessToken');

    if (refreshToken == null || accessToken == null) {
        return;
    }
    userStore.setToken(accessToken);
    useExtractRoles(accessToken);
};
export const refreshTokensIfNeeded = async () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const accessToken = localStorage.getItem('accessToken');

    if (refreshToken == null) {
        return;
    }

    try {
        isRefreshing = true;
        const response = await axios.post(`${process.env.REACT_APP_BASE_API_URI}/identity/refresh`, {
            accessToken,
            refreshToken,
        });

        if (response.status === 200) {
            const { accessToken, refreshToken: newRefreshToken } = response.data;
            localStorage.setItem('accessToken', accessToken);
            userStore.setToken(accessToken);
            useExtractRoles(accessToken);
            localStorage.setItem('refreshToken', newRefreshToken);
            addTokenToHeaders(accessToken);
            return Promise.resolve();
        } else {
            return Promise.reject('Failed to refresh token');
        }
    } catch (error) {
        console.error('Token refreshing Error:', error);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login';
        return Promise.reject(error);
    } finally {
        isRefreshing = false;
    }
};

axiosInstance.interceptors.request.use(
    async (config) => {
        if (!isRefreshing) {
            isRefreshing = true;
            await refreshTokensIfNeeded();
            isRefreshing = false;
        }

        const accessToken = localStorage.getItem('accessToken');
        if (accessToken) {
            config.headers['Authorization'] = `Bearer ${accessToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

export default axiosInstance;
