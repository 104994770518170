import React, { useEffect, useState } from 'react';
import './Navbar.scss';
import Logo from '../../images/icons/logo.svg';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LanguageSwitch from '../LanguageSwitch/LanguageSwitch';
import userStore from '../../MobXStorage/UserStore';
import { observer } from 'mobx-react-lite';
import { useIsAuthorized, useIsUserAnyTypeOfAdmin } from '../../hooks/UserHooks';

const Navbar: React.FC = observer(() => {
    const { t } = useTranslation();
    const [isAdmin, setIsAdmin] = useState(false);
    const isAuthorized = useIsAuthorized();
    useEffect(() => {
        setIsAdmin(useIsUserAnyTypeOfAdmin());
    }, [userStore.roles]);

    return (
        <nav className="navbar">
            <Link className="navbar-brand" to="/">
                <img src={Logo} alt="lifesightme - logo" />
            </Link>
            <div>
                <ul>
                    {isAuthorized && (
                        <li className="nav-item">
                            <Link className="body body-normal nav-link" to="/explanation">
                                {t('components.navbar.explanation')}
                            </Link>
                        </li>
                    )}
                    {isAdmin && (
                        <li className="nav-item">
                            <Link className="body body-normal nav-link" to="/admin-settings">
                                {t('components.navbar.settings')}
                            </Link>
                        </li>
                    )}
                </ul>
                <ul>
                    <li className="nav-item">
                        <LanguageSwitch />
                    </li>
                    <li className="nav-item"></li>
                </ul>
            </div>
        </nav>
    );
});

export default Navbar;
