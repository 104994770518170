import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import SearchLifestyleIntervention from '../../components/SearchLifestyleIntervention/SearchLifestyleIntervention';
import { ReactComponent as SVGLogo } from '../../images/icons/logo.svg';
import './SearchPage.scss';
import { useTranslation } from 'react-i18next';
import DiseaseSymptomInput from '../../components/DiseaseSymptomInput/DiseaseSymptomInput';
import { useService } from 'react-service-locator';
import { SearchService } from '../../services/search.service';
import { max } from 'lodash';
import { LifestyleInterventionService } from '../../services/lifestyle-intervention.service';

const SearchPage: React.FC = () => {
    const searchService = useService(SearchService);
    const lifestyleInterventionService = useService(LifestyleInterventionService);
    const { t } = useTranslation();
    useEffect(() => {
        const searchPageCard = document.getElementById('searchpage')?.getElementsByTagName('div')[0];
        if (!searchPageCard || !searchPageCard.classList.contains('card')) {
            return;
        }
        const lifestyleInterventionButtons = Array.from(document.getElementsByClassName('LifestyleInterventionButton'));
        const highestContentHeight = max(
            lifestyleInterventionButtons.map((c) => {
                const el = Array.from(c.getElementsByTagName('div')).find((c) => c.classList.contains('content'));
                if (!el) {
                    return;
                }
                return el.offsetHeight;
            }),
        );
        const marginBottom = max([0, (highestContentHeight ?? 0) - 155]);
        searchPageCard.style.marginBottom = `${marginBottom}px`;
    }, [lifestyleInterventionService.state.lifestyleInterventions]);
    useEffect(() => {
        lifestyleInterventionService.fetchLifestyleInterventions();
    }, []);
    return (
        <div id="searchpage" className="search" onLoad={() => searchService.clearResult()}>
            <div className="card">
                <div className="title">
                    <SVGLogo height={50} width="auto" />
                </div>
                <div className="disease-symptom-wrapper">
                    <h3>{t('pages.search-page.search-terms')}</h3>
                    <DiseaseSymptomInput />
                </div>
                <div className="lifestyle-intervention-wrapper" id="lifestyleInterventionWrapper">
                    <p className="body body-normal">{t('pages.search-page.lifestyle-intervention')}</p>
                    <SearchLifestyleIntervention />
                </div>
                <div className="btn-wrapper">
                    {!searchService.shouldRedirect ? (
                        <Link
                            onClick={() => {
                                searchService.search();
                            }}
                            to="/results"
                            className="body body-bold-big btn btn-enabled"
                        >
                            {t('search')}
                        </Link>
                    ) : (
                        <Link
                            to="/results"
                            className="body body-bold-big btn btn-disabled"
                            onClick={(e) => {
                                e.preventDefault();
                            }}
                        >
                            {t('search')}
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SearchPage;
