import { Service } from 'react-service-locator';
import axiosInstance from '../axios/axiosAuthInterceptor';
import { getKeyByValue, LanguageEnum } from '../components/LanguageSwitch/LanguageOption';

@Service()
export class AccountService {
    async fetchAccountSettings(email: string) {
        const response = await axiosInstance.get(`/AccountSettings/clientSettings/${email}`);
        const userLang = response.data.value.language;
        this.setLanguageInCookie(LanguageEnum[userLang as keyof typeof LanguageEnum]);
    }

    setLanguageInCookie = (lang: string) => {
        document.cookie = `language=${lang};expires=Thu, 01 Jan 2030 00:00:00 UTC;path=/;`; // Updated expiry to a future date
    };

    async setNewLanguage(newLanguage: keyof typeof LanguageEnum) {
        const email = localStorage.getItem('UserEmail');

        const languageKey = getKeyByValue(LanguageEnum, newLanguage.toString());

        if (languageKey && email) {
            const data = {
                UserEmail: email,
                newLanguage: parseInt(languageKey, 10),
            };
            await axiosInstance.post('/AccountSettings/writeNewLanguage', data);
        }
    }
}
