export const validateEmail = (email: string) => {
    // Regular expression for validating email addresses
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export const validatePhoneNumber = (phoneNumber: string) => {
    const phoneRegex = /^\+\d+$/;
    return phoneRegex.test(phoneNumber);
};

export const notEmpty = (values: { [key: string]: string }) => {
    for (const key in values) {
        if (values.hasOwnProperty(key) && (values[key] === null || values[key] === '')) {
            return key;
        }
    }
    return null;
};
