import React from 'react';
import { Link } from 'react-router-dom';
import './BreadCrumbs.scss';

interface BreadCrumbProp {
    to: string;
    name: string;
}

const BreadCrumbs: React.FC<{ breadcrumbs: BreadCrumbProp[] }> = (props: { breadcrumbs: BreadCrumbProp[] }) => (
    <ol className="breadcrumb">
        {props.breadcrumbs.map((v, i) => {
            if (i !== props.breadcrumbs.length - 1) {
                return (
                    <li className="body body-normal breadcrumb-item">
                        <Link to={v.to}>{v.name}</Link>
                    </li>
                );
            } else {
                return (
                    <li className="body body-normal breadcrumb-item active" aria-current="page">
                        {v.name}
                    </li>
                );
            }
        })}
    </ol>
);

export default BreadCrumbs;
