/* eslint-disable i18next/no-literal-string */
import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import nl from './Translations/nl';
import en from './Translations/en';

const resources = {
    nl: nl,
    en: en,
};

i18n.use(I18nextBrowserLanguageDetector).init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
        escapeValue: false,
    },
    resources,
});
