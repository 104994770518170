import React, { useEffect, useState } from 'react';
import './LoginPage.scss';
import { useTranslation } from 'react-i18next';
import { useService } from 'react-service-locator';
import SignedInput from '../../../components/SignedInput/SignedInput';
import { LoginService } from '../../../services/login-service';
import { useHistory } from 'react-router-dom';
import ErrorBanner from '../../../components/ErrorBanner/ErrorBanner';
import ErrorSign from '../../../images/icons/error-sign.svg';
import { validateEmail } from '../../../helpers/Validators';

const LoginPage: React.FC = () => {
    const loginService = useService(LoginService);
    const history = useHistory();
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [isInvalidEmail, setIsInvalidEmail] = useState(true);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [isError, setIsError] = useState(true);
    const [isCkicked, setIsClicked] = useState(false);
    useEffect(() => {
        setIsError(false);
        if (validateEmail(email)) {
            setIsInvalidEmail(false);
        } else {
            setIsInvalidEmail(true);
        }
    }, [email, setIsInvalidEmail]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        setIsClicked(true);
        event.preventDefault();
        const response = await loginService.loginStart(email, isCheckboxChecked);

        if (response.ok) {
            localStorage.setItem('UserEmail', email);
            setIsClicked(false);
            history.push('/login-pin');
        } else {
            const responseData = await response.json();

            if (responseData != null) {
                const errorMessage = responseData.value.error;
                if (errorMessage == 'Invalid Email') {
                    setIsError(true);
                    setIsClicked(false);
                }
            }
        }
    };

    return (
        <div className="mainLoginPage">
            <div id="loginpage" className={`search${isError ? ' error' : ''}`}>
                <>
                    {isError && (
                        <ErrorBanner
                            bannerHeader={t('pages.login-page.Failed-To-LogIn')}
                            bannerDescription={t('pages.login-page.Error-description')}
                            bannerIcon={ErrorSign}
                        ></ErrorBanner>
                    )}
                </>
                <form className="card" onSubmit={handleSubmit}>
                    <div className="title">{t('pages.login-page.Login')}</div>
                    <p className="text-container">{t('pages.login-page.Enter-Email')}</p>
                    <SignedInput
                        label={t('pages.login-page.Email-address')}
                        placeholder={t('pages.login-page.Email-example')}
                        setIsCheckboxChecked={setIsCheckboxChecked}
                        isCheckboxChecked={isCheckboxChecked}
                        inputValue={email}
                        setInputValue={setEmail}
                        checkboxLabel={t('pages.login-page.Remember-me')}
                        isError={isError}
                    ></SignedInput>
                    <button
                        disabled={isInvalidEmail || isCkicked}
                        type="submit"
                        className={`button ${!isInvalidEmail || !isCkicked ? ' button-ready' : ''}`}
                    >
                        {t('pages.login-page.Send-email-link')}
                    </button>
                    <p className="text-container last-message">{t('pages.login-page.Create-account')}</p>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
