import React, { useEffect, useState } from 'react';
import Lock from '../../../images/icons/lock.svg';
import './LoginPinPage.scss';
import { useTranslation } from 'react-i18next';
import { useService } from 'react-service-locator';
import SignedInput from '../../../components/SignedInput/SignedInput';
import { LoginService } from '../../../services/login-service';
import ErrorBanner from '../../../components/ErrorBanner/ErrorBanner';
import ErrorSign from '../../../images/icons/error-sign.svg';
import { useHistory } from 'react-router-dom';

const LoginPinPage: React.FC = () => {
    const loginService = useService(LoginService);
    const history = useHistory();
    const { t } = useTranslation();
    const [pin, setPin] = useState('');
    const [isValidPin, setIsValidPin] = useState(false);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        setIsError(false);
        if (validatePin(pin)) {
            setIsValidPin(false);
        } else {
            setIsValidPin(true);
        }
    }, [pin, setIsValidPin]);

    const validatePin = (pin: string) => {
        // Regular expression for validating PIN (only numbers, exactly 4 digits)
        const pinRegex = /^\d{4}$/;
        return pinRegex.test(pin);
    };
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        // eslint-disable-next-line i18next/no-literal-string
        const email = localStorage.getItem('UserEmail');
        if (email != null) {
            const response = await loginService.loginPinVerify(email, pin);
            if (response.ok) {
                const responseData = await response.json();
                localStorage.setItem('accessToken', responseData.accessToken);
                localStorage.setItem('refreshToken', responseData.refreshToken);
                history.push('/');
            } else {
                const responseData = await response.json();
                if (responseData != null) {
                    const errorMessage = responseData.value.error;
                    if (errorMessage == 'Invalid Pin') {
                        setIsError(true);
                    }
                }
            }
        }
    };

    return (
        <div className="mainLoginPinPage">
            <div id="loginpinpage" className="search">
                <>
                    {isError && (
                        <ErrorBanner
                            bannerHeader={t('pages.loginPin-page.Error-header')}
                            bannerDescription={t('pages.loginPin-page.Error-description')}
                            bannerIcon={ErrorSign}
                        ></ErrorBanner>
                    )}
                </>
                <form className="card" onSubmit={handleSubmit}>
                    <div className="title">{t('pages.loginPin-page.Unique-PIN')}</div>
                    <p className="text-container" style={{ marginTop: '24px', padding: '0' }}>
                        {t('pages.loginPin-page.Enter-code')}
                    </p>
                    <SignedInput
                        placeholder={t('pages.loginPin-page.Numeric-code')}
                        inputValue={pin}
                        setInputValue={setPin}
                        icon={Lock}
                        isError={isError}
                    ></SignedInput>
                    <button
                        disabled={isValidPin}
                        type="submit"
                        className={`button ${!isValidPin ? ' button-ready' : ''}`}
                    >
                        {t('pages.loginPin-page.Confirm')}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginPinPage;
