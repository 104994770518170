import React, { useState } from 'react';
import './StyledDropdown.scss';

interface StyledDropdownProps {
    options: string[];
    onSelect: (option: string) => void;
    sign?: string;
    placeHolder: string;
    isError?: boolean;
}

const StyledDropdown: React.FC<StyledDropdownProps> = ({ options, onSelect, sign, placeHolder, isError }) => {
    const [selectedOption, setSelectedOption] = useState('');

    const handleSelect = (option: string) => {
        setSelectedOption(option);
        onSelect(option);
    };

    return (
        <div className="dropdown">
            <div className="sign">{sign}</div>
            <div className="select-container">
                <select
                    className={`select__field__settings select-field${selectedOption ? '' : ' placeholder'}${
                        selectedOption === '' ? ' empty' : ''
                    }`}
                    value={selectedOption}
                    onChange={(e) => handleSelect(e.target.value)}
                >
                    <option className="holder_style" value="" disabled hidden>
                        {placeHolder}
                    </option>
                    {options.map((option, index) => (
                        <option key={index} value={option}>
                            {option}
                        </option>
                    ))}
                </select>
                <div className="arrow-icon"></div>
            </div>
        </div>
    );
};

export default StyledDropdown;
