import { Service } from 'react-service-locator';
import axiosInstance from '../axios/axiosAuthInterceptor';
export interface RegisterClientDto {
    Client: {
        GeneralEmail: string;
        GeneralPhone: string;
        Company: string;
        Address: string;
    };
    User: {
        UserEmail: string;
        UserPhone: string;
        FirstName: string;
        LastName: string;
        Title: number;
    };
}

export interface RegisterMasterUserDto {
    User: {
        UserEmail: string;
        UserPhone: string;
        FirstName: string;
        LastName: string;
        Title: number;
    };
}

@Service()
export class RegisterService {
    async registerNewClient(registerData: RegisterClientDto) {
        const response = await axiosInstance.post('/User/registerNewClient', registerData);
        const responseData = response.data;
        if (!responseData.succeeded) {
            return {
                isError: true,
                errorDescription: responseData.errors[0].description,
                errorType: responseData.errors[0].code,
            };
        } else {
            return { isError: false };
        }
    }

    async registerNewMasterUser(registerData: RegisterMasterUserDto) {
        const response = await axiosInstance.post('/User/registerNewMasterUser', registerData);
        const responseData = response.data;
        if (!responseData.succeeded) {
            return {
                isError: true,
                errorDescription: responseData.errors[0].description,
                errorType: responseData.errors[0].code,
            };
        } else {
            return { isError: false };
        }
    }
}
