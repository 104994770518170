import { jwtDecode, JwtPayload } from 'jwt-decode';
import userStore from '../MobXStorage/UserStore';
import { toJS } from 'mobx';

interface PayloadJwt extends JwtPayload {
    'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': string[];
}

export const useExtractRoles = (token: string): string[] | undefined => {
    if (token) {
        // Decode the JWT token
        const jwtPayload = jwtDecode<PayloadJwt>(token);
        // Return the roles from the decoded payload
        const roles = jwtPayload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
        if (roles) {
            userStore.setRoles(roles);
        }
    } else {
        // Handle the case when there's no token
        // Depending on your application's requirements, you might want to return a default value or throw an error
        return undefined; // or throw new Error('Access token not found');
    }
};

export const useGetRoles = (): string[] | undefined => {
    const roles = toJS(userStore.roles);
    if (roles) {
        return roles;
    } else {
        return undefined;
    }
};

export const useIsAuthorized = () => {
    const refreshToken = localStorage.getItem('refreshToken');
    return refreshToken != null;
};

export const useIsUserAnyTypeOfAdmin = (initialRoles?: string[]): boolean => {
    if (initialRoles) {
        return initialRoles.includes('MASTER') || initialRoles.includes('ADMIN');
    } else {
        const roles = useGetRoles();
        if (roles) {
            return roles.includes('MASTER') || roles.includes('ADMIN');
        }
    }
    return false;
};
